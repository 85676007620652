import { FEATURE_FLAGS } from '@appTypes/common';
import { PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import { PerformanceOverTimeParamsDto } from '@appTypes/models/common.dto';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { convertLocalDateToApiString, createParams } from '@utils/utils';
import { coreApi } from './core';

const dropCalculatorFlag = isFeatureFlagEnabled(FEATURE_FLAGS.DROP_CALCULATOR)
  ? '&drop_calculator=true'
  : '';

const performanceOverTimeApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getPerformanceOverTime: builder.query<PerformanceOverTimeDto, PerformanceOverTimeParamsDto>({
      query: ({ startDate, endDate, siteId, ...params }) =>
        `sites/${siteId}/performance?${createParams({
          start: convertLocalDateToApiString(startDate),
          end: convertLocalDateToApiString(endDate),
          ...params,
        })}${dropCalculatorFlag}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetPerformanceOverTimeQuery } = performanceOverTimeApi;
