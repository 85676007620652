import { AppEnvironment, PathParam } from '@appTypes/common';
import { ENDPOINT_WITH_FILTERS } from '@appTypes/models/common.dto';
import { MILLIS_MINUTE, MILLIS_SECOND } from '@constants';
import CONFIG from '@settings/config';
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  parseISO,
  startOfDay,
  subDays,
  addDays,
  addMonths,
  addWeeks,
  addYears,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import jwt_decode from 'jwt-decode';

export const trimSlashes = (str: string) => str.replace(/^\/|\/$/g, '');

export const getTimeDifference = (startDateTime: Date, endDateTime: Date): string => {
  const diffInMinutes = differenceInMinutes(endDateTime, startDateTime);

  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''}`;
  }

  const diffInHours = differenceInHours(endDateTime, startDateTime);

  if (diffInHours >= 24) {
    const diffInDays = differenceInDays(endDateTime, startDateTime);
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''}`;
  }

  return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''}`;
};

export const createParams = (
  paramObj: Record<string, number | string | boolean | null | undefined | Date | string[]>,
): string =>
  Object.keys(paramObj)
    .map((key) => {
      const value = paramObj[key];
      if (
        typeof value === 'undefined' ||
        value === null ||
        (Array.isArray(value) && (value.length === 0 || !value.join('').length)) ||
        (typeof value === 'string' && value.length === 0)
      )
        return '';

      const encodedKey = encodeURIComponent(key);
      let encodedValue: string;

      if (Array.isArray(value)) {
        encodedValue = value.map((val) => encodeURIComponent(val)).join(',');
      } else if (typeof value === 'boolean') {
        encodedValue = value.toString();
      } else {
        encodedValue = encodeURIComponent(value.toString());
      }

      return `${encodedKey}=${encodedValue}`;
    })
    .filter((item) => item.length)
    .join('&');

export const convertUtcStringToLocalString = (utcDateTimeString: string) => {
  const utcDateTimeWithoutMillis = utcDateTimeString.split('.')[0];
  const utcDatetimeIso = new Date(`${utcDateTimeWithoutMillis}Z`);
  const offsetMinutes = utcDatetimeIso.getTimezoneOffset();
  const localTime = new Date(utcDatetimeIso.getTime() - offsetMinutes * MILLIS_MINUTE);

  return localTime.toISOString().slice(0, -1);
};

export const convertLocalDateToApiString = (date: Date) => {
  const dateISOStr = date.toISOString();

  const parsedTime = parseISO(dateISOStr);

  return format(utcToZonedTime(parsedTime, 'UTC'), 'yyyy-MM-dd HH:mm:ss', {
    timeZone: 'UTC',
  }).replace(' ', 'T');
};

export const getLastStartOfDayBeforeToday = (): Date => {
  const today = new Date();
  const yesterday = subDays(today, 1);

  return startOfDay(yesterday);
};

export const offsetDateByDuration = (inputDate: Date, duration: string): Date => {
  const regex = /([-]?\d+)([wdMy])/;
  const match = duration.match(regex);

  if (!match) {
    throw new Error(
      'Invalid duration format. Use formats like "1w" for 1 week, "2d" for 2 days, etc.',
    );
  }

  const amount = parseInt(match[1], 10);
  const unit = match[2];

  switch (unit) {
    case 'd':
      return amount >= 0 ? addDays(inputDate, amount) : subDays(inputDate, Math.abs(amount));
    case 'w':
      return amount >= 0 ? addWeeks(inputDate, amount) : subWeeks(inputDate, Math.abs(amount));
    case 'M':
      return amount >= 0 ? addMonths(inputDate, amount) : subMonths(inputDate, Math.abs(amount));
    case 'y':
      return amount >= 0 ? addYears(inputDate, amount) : subYears(inputDate, Math.abs(amount));
    default:
      throw new Error(
        'Invalid duration unit. Use "d" for days, "w" for weeks, "M" for months, "y" for years.',
      );
  }
};

export const getTimeInfo = () => {
  const now = new Date();

  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!localTimeZone || localTimeZone === 'Etc/Unknown') {
    return {
      localTimeZone: 'Unknown',
      localDatetime: 'Unknown',
    };
  }

  const localDatetime = format(now, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: localTimeZone });

  return {
    localTimeZone,
    localDatetime,
  };
};

export const isValidJwtToken = (token: Nullable<string>): boolean => {
  if (!token) {
    return false;
  }

  try {
    jwt_decode(token);
    return true;
  } catch (error) {
    return false;
  }
};

export const getTokenInfo = (token: Nullable<string>) => {
  if (!isValidJwtToken(token)) {
    return {
      validUntil: new Date(0),
      expired: true,
      validTime: 0,
    };
  }

  const decodedToken = jwt_decode<{ exp?: number }>(token!);
  const now = new Date();

  if (typeof decodedToken?.exp !== 'number') {
    return {
      validUntil: new Date(0),
      expired: true,
      validTime: 0,
    };
  }

  const expirationTimeMillis = decodedToken.exp * MILLIS_SECOND;
  const validUntil = new Date(expirationTimeMillis);
  const expired = expirationTimeMillis <= now.getTime();
  const validTime = Math.ceil((expirationTimeMillis - now.getTime()) / (MILLIS_SECOND * 60));

  return {
    validUntil,
    expired,
    validTime,
  };
};

export const getEnvironment = () => (process.env.NODE_ENV as AppEnvironment) || 'development';
export const isProduction = () => getEnvironment() === 'production';
export const isDevelopment = () => getEnvironment() === 'development';

export const poundsFormatter = Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

export const formatCurrency = (value: number) => {
  const formatted = poundsFormatter.format(value);

  if (value < 0) {
    return `-${formatted.slice(2)}`;
  }

  return formatted.slice(1);
};

export const createEndpointQueryForAllItems = (endpointName: ENDPOINT_WITH_FILTERS) =>
  `${endpointName}?page=1&page_size=${CONFIG.ENDPOINTS.ALL_ITEMS_PAGESIZE}`;

export const getMinMaxFromArray = (inputArray: number[]) => {
  const min = Math.min(...inputArray);
  const max = Math.max(...inputArray);
  return { min, max };
};

export const getRgbaColor = (hexColor: string, opacity = 1) => {
  const hexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  const rgbColors = hexColor
    .replace(hexRegex, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));

  if (!rgbColors || rgbColors.length < 3 || rgbColors.some((color) => Number.isNaN(color))) {
    throw new Error('Bad hex given');
  }

  const [r, g, b] = rgbColors;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const space = (size: number) => `${size * 8}px`;

export const cssImp = (cssVal: string) => `${cssVal} !important`;
export const getFormattedTimeZone = () => {
  const now = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const offsetInMinutes = now.getTimezoneOffset();
  const offsetInHours = offsetInMinutes / 60;

  const formattedOffset =
    offsetInHours === 0 ? 'UTC' : `UTC${offsetInHours > 0 ? '-' : '+'}${Math.abs(offsetInHours)}`;

  return `${timeZone} (${formattedOffset})`;
};

export const normaliseString = (text: Nullable<string>) => {
  if (!text) {
    return '';
  }

  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const createParam = (name: string, pathParam: PathParam) => {
  const paramName = `:${name}`;
  return pathParam === null ? paramName : pathParam;
};

export const idParam = (id: PathParam) => createParam('id', id);
