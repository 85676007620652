import { PropsWithChildren, useState } from 'react';
import { FEATURE_FLAGS } from '@appTypes/common';
import { CenterX, Flex, PaperLight } from '@components/LayoutUtils';
import { TitleBar } from '@components/TitleBar/TitleBar';
import CONFIG, { featureFlags } from '@config';
import { useCurrentUserInfo } from '@hooks';
import { SvgIconComponent, Person } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AdminIcon from '@mui/icons-material/LocalPoliceOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Box, Chip, Switch, Typography } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import {
  isFeatureFlagEnabled,
  disableFeatureFlag,
  enableFeatureFlag,
  getVisibleFeatureFlags,
} from '@services/featureFlags';
import { createFullName } from '@utils/data/createFullName';
import { getEnvironment, getFormattedTimeZone } from '@utils/utils';
import { useSelector } from 'react-redux';

const InfoCard = ({
  title,
  IconComponent,
  children,
}: PropsWithChildren<{ title: string; IconComponent: SvgIconComponent }>) => (
  <PaperLight sx={{ p: 2 }}>
    <CenterX gap={1} pb={1}>
      <IconComponent color="secondary" />
      <Typography variant="h6">{title}</Typography>
    </CenterX>
    {children}
  </PaperLight>
);

const AdminTools = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const { isWattstorUser } = useCurrentUserInfo();

  if (!currentUser || !isWattstorUser) {
    return null;
  }

  const appInfo = [
    { label: 'Version', value: CONFIG.APP_VERSION, testId: 'app-version' },
    {
      label: 'Environment',
      value: getEnvironment().toUpperCase(),
      testId: 'app-env',
    },
    {
      label: 'Timezone',
      value: getFormattedTimeZone(),
      testId: 'app-timezone',
    },
  ];

  const userInfo = [
    { label: 'User ID', value: currentUser?.id, testId: 'user-id' },
    { label: 'E-mail', value: currentUser?.email, testId: 'user-e-mail' },
    {
      label: 'Name',
      value: createFullName(currentUser.first_name, currentUser.last_name),
      testId: 'user-name',
    },
    { label: 'Is active', value: currentUser?.is_active ? 'Yes' : 'No', testId: 'user-is-active' },
    { label: 'User role', value: currentUser?.role?.name, testId: 'user-role' },
  ];

  return (
    <Box>
      <Flex flexDirection="column" gap={2}>
        <TitleBar title="Admin tools" IconComponent={AdminIcon} />

        <InfoCard title="Application" IconComponent={InfoOutlinedIcon}>
          {appInfo.map(({ label, value, testId }) => (
            <CenterX gap={1} py={0.3} key={label}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', minWidth: '80px' }}>
                {label}:
              </Typography>
              <Typography variant="body1" data-testid={testId}>
                {value}
              </Typography>
            </CenterX>
          ))}
        </InfoCard>

        <InfoCard title="Logged user" IconComponent={Person}>
          {userInfo.map(({ label, value, testId }) => (
            <CenterX gap={1} py={0.3} key={label}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', minWidth: '80px' }}>
                {label}:
              </Typography>
              <Typography variant="body1" data-testid={testId}>
                {value}
              </Typography>
            </CenterX>
          ))}
        </InfoCard>

        <FeatureFlagsList />
      </Flex>
    </Box>
  );
};

function FeatureFlagsList() {
  const [flagStates, setFlagStates] = useState(() => {
    const initialStates: { [key in FEATURE_FLAGS]: boolean } = {} as any;
    featureFlags.forEach((flag) => {
      initialStates[flag.name] = isFeatureFlagEnabled(flag.name);
    });
    return initialStates;
  });

  const handleToggle = (flagName: FEATURE_FLAGS) => {
    if (isFeatureFlagEnabled(flagName)) {
      disableFeatureFlag(flagName);
    } else {
      enableFeatureFlag(flagName);
    }

    setFlagStates((prevStates) => ({
      ...prevStates,
      [flagName]: !prevStates[flagName],
    }));
  };

  const visibleFeatureFlags = getVisibleFeatureFlags();

  return visibleFeatureFlags.length ? (
    <InfoCard title="Features" IconComponent={SettingsSuggestIcon}>
      {visibleFeatureFlags.map(({ name, label, environments }) => (
        <CenterX key={name}>
          <Switch
            checked={flagStates[name]}
            color="secondary"
            onChange={() => handleToggle(name)}
          />
          <Typography variant="body1">{label}</Typography>

          {environments.map((env) => (
            <Chip
              label={env}
              key={`${name}-${env}`}
              variant="outlined"
              color={env === 'development' ? 'success' : 'primary'}
              size="small"
              sx={{ ml: 1 }}
            />
          ))}
        </CenterX>
      ))}
    </InfoCard>
  ) : null;
}

export default AdminTools;
