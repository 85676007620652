import { PropsWithChildren } from 'react';
import { CenterX, CenterXY, Flex } from '@components/LayoutUtils';
import { usePalette } from '@hooks/usePalette';
import splashBackgroundSrc from '@images/splash.jpg';
import BatteryIcon from '@mui/icons-material/BatteryChargingFullRounded';
import PlugIcon from '@mui/icons-material/ElectricalServicesRounded';
import SunIcon from '@mui/icons-material/LightModeRounded';
import { Box, Typography, styled } from '@mui/material';
import { palette } from 'styles/palette';
import { Header } from './Header/Header';

const Splash = styled(CenterXY)`
  flex: 1;
  background-color: ${palette.primary.main};
  background-image: url('${splashBackgroundSrc}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;

  & svg {
    fill: ${({ theme }) => theme.palette.getContrastText(theme.palette.primary.main)};
    font-size: 50px;
  }
`;

export const UnauthenticatedWrapper = ({ children }: PropsWithChildren) => {
  const currentPalette = usePalette();

  return (
    <>
      <Header />
      <Box data-testid="un-wrapper" height="calc(100vh - 80px)">
        <Flex height="100%">
          <CenterX my={8} mx={4} flexDirection="column" flex={1}>
            {children}
          </CenterX>
          <Splash sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box>
              <CenterXY gap={2} pb={3}>
                <Typography color={currentPalette.background.default}>
                  <SunIcon />
                  <BatteryIcon />
                  <PlugIcon />
                </Typography>
              </CenterXY>
              <Typography textAlign="center" variant="h4" color={currentPalette.background.default}>
                Guard your energy
              </Typography>
            </Box>
          </Splash>
        </Flex>
      </Box>
    </>
  );
};
