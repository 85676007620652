import { useState } from 'react';
import { Can } from '@components/Can';
import { CenterX, Flex } from '@components/LayoutUtils';
import RoleIcon from '@components/RoleIcon/RoleIcon';
import { useRedirect, useCurrentUserInfo } from '@hooks';
import logo from '@images/logotype.png';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Box,
  Drawer,
} from '@mui/material';
import { authActions } from '@redux/slices/auth';
import { selectProfileSwitcherSlice } from '@redux/slices/profileSwitch';
import { MAIN_MENU_WIDTH } from '@settings/constants';
import { space, getRgbaColor } from '@utils/utils';
import { navItems } from 'navItems';
import { paths } from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { palette } from 'styles/palette';
import { ProfileSwitcher } from './ProfileSwitcher/ProfileSwitcher';

export const Navbar = () => {
  const { isWattstorUser, fullName } = useCurrentUserInfo();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const redirect = useRedirect();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUserInfo();
  const { sessionActive } = useSelector(selectProfileSwitcherSlice);

  return (
    <Drawer
      open
      PaperProps={{
        sx: { width: MAIN_MENU_WIDTH, p: 1.5, boxSizing: 'border-box' },
      }}
      variant="persistent"
      className="appScrollBar"
    >
      <Box p={2.5}>
        <Link to={paths.sites} title="Back to homepage">
          <img src={logo} alt="logo" />
        </Link>
      </Box>

      {!sessionActive ? (
        <CenterX justifyContent="space-between" pb={2} px={2}>
          <CenterX gap={1}>
            <RoleIcon role={currentUser?.role.name} />
            <Typography variant="body1">
              <strong>{fullName}</strong>
            </Typography>
          </CenterX>
          <Button
            onClick={() => dispatch(authActions.logout())}
            variant="secondaryFade"
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </CenterX>
      ) : null}

      <ProfileSwitcher />

      {navItems.map(
        ({
          title,
          items,
          id,
          IconComponent,
          permissions,
          alwaysExpanded,
          path,
          wattstorUserOnly,
          CustomItemComponent,
        }) => {
          const isCurrentPath = pathname?.includes(path || '');
          const subItems = items?.map(
            ({ path: subItemPath, title: subItemTitle, id: subItemId }) => (
              <AccordionDetails key={subItemId}>
                <Typography fontWeight={900}>
                  <Link
                    to={subItemPath}
                    style={{
                      color: palette.primary.main,
                      textDecoration: 'none',
                    }}
                  >
                    {subItemTitle}
                  </Link>
                </Typography>
              </AccordionDetails>
            ),
          );

          const redirectOnClick = path ? () => redirect(() => path) : undefined;

          const { isExpanded, onChange, onClick } = (() => {
            if (alwaysExpanded) {
              return {
                isExpanded: true,
                onClick: redirectOnClick,
                onChange: undefined,
              };
            }

            if (CustomItemComponent || subItems) {
              return {
                isExpanded: expanded === id,
                onChange: handleChange(id),
                onClick: undefined,
              };
            }

            return {
              isExpanded: false,
              onClick: redirectOnClick,
              onChange: undefined,
            };
          })();

          if (wattstorUserOnly && !isWattstorUser) {
            return null;
          }

          return (
            <Can key={id} permissions={permissions || []}>
              <Accordion expanded={isExpanded} onChange={onChange} sx={{ boxShadow: 'none' }}>
                <AccordionSummary onClick={onClick} sx={{ margin: 0 }}>
                  <Flex
                    gap="6px"
                    borderRadius={space(4)}
                    border={`1px solid  ${
                      isCurrentPath ? palette.secondary.main : palette.neutral.main
                    }`}
                    flex={1}
                    height="56px"
                    alignItems="center"
                    p="0 32px"
                    bgcolor={
                      isCurrentPath ? getRgbaColor(palette.secondary.main, 0.1) : 'transparent'
                    }
                  >
                    {IconComponent && <IconComponent color="secondary" />}
                    <Typography color="primary" fontWeight="bold">
                      {title}
                    </Typography>
                  </Flex>
                </AccordionSummary>
                {CustomItemComponent ? <CustomItemComponent /> : subItems}
              </Accordion>
            </Can>
          );
        },
      )}
    </Drawer>
  );
};
