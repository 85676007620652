import { ConfiguredItem } from '@components/ConfigurationList/types';
import { Tabs } from '@components/Tabs/Tabs';
import { Box, Tab } from '@mui/material';

const tabsLabels: { [key in ConfiguredItem]: string } = {
  [ConfiguredItem.SITES]: 'Sites',
  [ConfiguredItem.USERS]: 'Users',
  [ConfiguredItem.ORGANIZATIONS]: 'Organizations',
  [ConfiguredItem.ECDS]: 'ECDs',
};

export const RelationsTabs = ({
  value,
  tabs,
  onValueChange,
}: {
  value: ConfiguredItem;
  tabs: ConfiguredItem[];
  onValueChange: (value: ConfiguredItem) => void;
}) => (
  <Box width="100%">
    <Tabs<ConfiguredItem> value={value} onChange={(_, v) => onValueChange(v)}>
      {tabs.map((tab) => (
        <Tab key={tab} value={tab} label={tabsLabels[tab]} />
      ))}
    </Tabs>
  </Box>
);
