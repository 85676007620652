import { ApiError } from '@appTypes/api/error';
import {
  ActionType,
  RequestSuccessActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import { OrganizationFilters } from '@appTypes/organization/filters';

export enum UsersNonFilteredActionsType {
  REQUEST_USERS_NON_FILTERED = 'REQUEST_USERS_NON_FILTERED',
  REQUEST_USERS_NON_FILTERED_SUCCESS = 'REQUEST_USERS_NON_FILTERED_SUCCESS',
  REQUEST_USERS_NON_FILTERED_FAILED = 'REQUEST_USERS_NON_FILTERED_FAILED',
  FETCHED_USERS_NON_FILTERED = 'FETCHED_USERS_NON_FILTERED',
  UPDATE_USERS_NON_FILTERED_PAGES = 'UPDATE_USERS_NON_FILTERED_PAGES',
}

export type RequestUsersNonFiltered =
  ActionType<UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED>;

export type RequestUsersNonFilteredSuccess = RequestSuccessActionType<
  UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED_SUCCESS,
  UserSimpleDto
>;

export type FetchedUsersNonFiltered = ActionType<
  UsersNonFilteredActionsType.FETCHED_USERS_NON_FILTERED,
  OrganizationFilters
>;

export type RequestUsersNonFilteredFailed = ActionType<
  UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED_FAILED,
  ApiError
>;

export type UpdateUsersNonFilteredPages = UpdateFilterActionType<
  UsersNonFilteredActionsType.UPDATE_USERS_NON_FILTERED_PAGES,
  OrganizationFilters
>;
