import { organizationsAPI } from '@api/organization';
import { OrganizationSimpleDto } from '@appTypes/models/organization.dto';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { fork } from 'redux-saga/effects';
import {
  requestOrganizationsNonFiltered,
  requestOrganizationsNonFilteredSuccess,
  requestOrganizationsNonFilteredFailed,
} from './actionCreators';
import { OrganizationsNonFilteredActionsType } from './actionTypes';
import {
  OrganizationsNonFilteredSelectorReturn,
  getOrganizationsNonFilteredSelector,
} from './selectors';

export function* organizationsNonFilteredWatcher() {
  yield fork<
    CreateSagaCrudWatcher<
      OrganizationsNonFilteredSelectorReturn,
      OrganizationSimpleDto,
      unknown,
      unknown
    >
  >(createSagaCRUDWatcher, {
    selector: getOrganizationsNonFilteredSelector,
    getList: {
      actionName: OrganizationsNonFilteredActionsType.FETCHED_ORGANIZATIONS_NON_FILTERED,
      updateFilterActionName:
        OrganizationsNonFilteredActionsType.UPDATE_ORGANIZATIONS_NON_FILTERED_PAGES,
      apiCall: organizationsAPI.getOrganizations,
      request: requestOrganizationsNonFiltered,
      requestSuccess: requestOrganizationsNonFilteredSuccess,
      requestFailed: requestOrganizationsNonFilteredFailed,
    },
  });
}
