import { useState } from 'react';
import { useGetNotificationRulesQuery } from '@apiRtk/notificationRules';
import { FilterValues } from '@appTypes/helpers/filters';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { AddButton } from '@components/Buttons/AddButton';
import { CenterX } from '@components/LayoutUtils';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useOnUpdate, useRedirect, useSearchParamsAsState } from '@hooks';
import { SearchParamsStateType } from '@hooks/useSearchParamsAsState';
import { Button } from '@mui/material';
import { Toolbar } from '@pages/Notifications/Toolbar';
import { NotificationRuleList } from './DataGrid/DataGrid';
import { EditRulePanel } from './EditRule/EditRulePanel';

const filtersDefaults: SearchParamsStateType = {
  descending: { type: 'string', default: 'true' },
  order_by: { type: 'string', default: 'site_id' },
  page: { type: 'number', default: 1 },
  page_size: { type: 'number', default: 5 },
  site_id: { type: 'number', default: null },
  search_string: { type: 'string', default: '' },
  state: { type: 'string', default: 'all' },
};

const NotificationRules = () => {
  const [filterParams, setFilterParams, removeFilterParam] =
    useSearchParamsAsState(filtersDefaults);

  const redirect = useRedirect();

  const { refetch, data, error, isLoading, isFetching } = useGetNotificationRulesQuery(
    filterParams,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [ruleIdToEdit, setRuleIdToEdit] = useState<Nullable<number>>(null);
  const [createRuleMode, setCreateRuleMode] = useState(false);

  const formCleanup = () => {
    setRuleIdToEdit(null);
    setCreateRuleMode(false);
  };

  const setFilter = (filterName: string, filterValue: FilterValues) => {
    // Note: All non-page filters resets page
    const pageReset = filterName !== 'page' ? { page: 1 } : {};

    if (filterValue === null || typeof filterValue === 'undefined') {
      removeFilterParam(filterName);
    } else {
      setFilterParams({ [filterName]: filterValue, ...pageReset });
    }
  };

  useOnUpdate(() => {
    if (!data) {
      formCleanup();
    }
  }, [data]);

  const onRedirectButtonClick = () => {
    redirect((paths) => paths.notifications);
    formCleanup();
  };

  return (
    <>
      <TitleBar title="Notification rules" pb={2} />
      <CenterX justifyContent="space-between">
        <Button onClick={onRedirectButtonClick}>Go to notifications list</Button>
        <AddButton onClick={() => setCreateRuleMode(true)}>Add new rule</AddButton>
      </CenterX>
      <Toolbar filters={filterParams} setFilters={setFilter} noSiteFilter />
      {error ? (
        <AlertWithRefetch onRetryClick={refetch}>
          An error occured while loading notification rules
        </AlertWithRefetch>
      ) : (
        <NotificationRuleList
          setRuleIdToEdit={setRuleIdToEdit}
          itemsCount={data?.count!}
          items={data?.rules || []}
          loading={isLoading || isFetching}
          filters={filterParams}
          setFilters={setFilter}
        />
      )}
      <EditRulePanel
        ruleId={ruleIdToEdit}
        createRuleMode={createRuleMode}
        handleFormClose={formCleanup}
      />
    </>
  );
};

export default NotificationRules;
