import { DefaultFilters } from '@appTypes/helpers/redux';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { UsersAction } from '../users/actionCreators';
import { UserActionsType } from '../users/actionTypes';
import { UsersNonFilteredAction } from './actionCreators';
import { UsersNonFilteredActionsType } from './actionTypes';

const initialState = {
  usersNonFiltered: null as Nullable<WPage<UserSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  filters: {
    page: 1,
    pageSize: 10,
  } as DefaultFilters,
};

export type UserNonFilteredStateType = typeof initialState;

export const usersNonFilteredReducer = (
  state = initialState,
  action: UsersNonFilteredAction | UsersAction,
): UserNonFilteredStateType => {
  const reducerHandler = createReducerHandler<UserNonFilteredStateType, UserSimpleDto>(
    'usersNonFiltered',
    state,
  );

  switch (action.type) {
    case UsersNonFilteredActionsType.UPDATE_USERS_NON_FILTERED_PAGES:
      return reducerHandler.updateFilter(action.payload);
    case UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED:
      return reducerHandler.request();
    case UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED_SUCCESS:
      return reducerHandler.requestSuccess(action.payload);
    case UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED_FAILED:
      return reducerHandler.requestFailed(action.payload);
    case UserActionsType.CREATE_USER_SUCCESS:
      return initialState;
    case UserActionsType.UPDATE_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
