import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useSendVerificationEmailMutation,
  useSetPasswordMutation,
  useVerifyCodeMutation,
} from '@apiRtk/login';
import { SetPasswordDto } from '@appTypes/models/auth.dto';
import { AppLink } from '@components/AppLink';
import { BasicForm, BasicFormField } from '@components/BasicForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRedirect, useURLQuery } from '@hooks';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { dictionary } from '@settings/dictionary';
import { paths } from 'paths';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { emailValidation, passwordValidation, verifyCodeValidation } from './validations';

enum ForgotPasswordStage {
  SEND_EMAIL = 'SEND_EMAIL',
  VERIFY_CODE = 'VERIFY_CODE',
  SET_PASSWORD = 'SET_PASSWORD',
}

type FormStages = {
  [key in ForgotPasswordStage]: {
    field: BasicFormField;
    submitBtnLabel: string;
    validationSchema: typeof emailValidation;
    stageSuccessMessage: string;
    stageErrorMessage: string;
    nextStage?: ForgotPasswordStage;
  };
};

const stages: FormStages = {
  [ForgotPasswordStage.SEND_EMAIL]: {
    field: { label: 'E-mail', name: 'email', useInputLabel: true },
    submitBtnLabel: 'Send e-mail',
    validationSchema: emailValidation,
    stageSuccessMessage: 'Verification code has been sent to your email address.',
    stageErrorMessage: 'Error occured while sending verification email.',
    nextStage: ForgotPasswordStage.VERIFY_CODE,
  },
  [ForgotPasswordStage.VERIFY_CODE]: {
    field: {
      label: 'Verification code',
      name: 'code',
      useInputLabel: true,
      inputProps: { maxLength: 6 },
    },
    submitBtnLabel: 'Verify code',
    validationSchema: verifyCodeValidation,
    stageSuccessMessage: 'Code verification was successful.',
    stageErrorMessage: 'Code verification was not successful.',
    nextStage: ForgotPasswordStage.SET_PASSWORD,
  },
  [ForgotPasswordStage.SET_PASSWORD]: {
    field: {
      label: 'New password',
      name: 'password',
      useInputLabel: true,
      type: 'password',
    },
    submitBtnLabel: 'Set new password',
    validationSchema: passwordValidation,
    stageSuccessMessage: 'Password has been successfully changed.',
    stageErrorMessage: 'Error occured while changing your password.',
  },
};

type VerifyCodeUrlParams = {
  email: string;
};

type StageErrorData = {
  detail: string | string[];
};

type StageError = {
  status: number;
  data: StageErrorData;
};

const ForgotPassword = () => {
  const urlQuery = useURLQuery<VerifyCodeUrlParams>();
  const emailPrefilled = urlQuery.getURLParamValue('email');

  const [
    sendVerificationEmail,
    { isSuccess: sendEmailSuccess, isError: isSendEmailError, error: sendEmailError },
  ] = useSendVerificationEmailMutation();

  const [
    sendVerifyCode,
    {
      isSuccess: sendVerifyCodeSuccess,
      isError: isSendVerifyCodeError,
      error: sendVerifyCodeError,
    },
  ] = useVerifyCodeMutation();

  const [
    setPassword,
    { isSuccess: isSetPasswordSuccess, isError: isSetPasswordError, error: sendSetPasswordError },
  ] = useSetPasswordMutation();

  const [stage, setStage] = useState(
    emailPrefilled!! ? ForgotPasswordStage.VERIFY_CODE : ForgotPasswordStage.SEND_EMAIL,
  );

  const { submitBtnLabel, validationSchema } = stages[stage];

  const { register, handleSubmit, watch, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const redirect = useRedirect();

  const successStatuses = useMemo(
    () => ({
      [ForgotPasswordStage.SEND_EMAIL]: sendEmailSuccess,
      [ForgotPasswordStage.VERIFY_CODE]: sendVerifyCodeSuccess,
      [ForgotPasswordStage.SET_PASSWORD]: isSetPasswordSuccess,
    }),
    [isSetPasswordSuccess, sendEmailSuccess, sendVerifyCodeSuccess],
  );

  const errorStatuses = useMemo(
    () => ({
      [ForgotPasswordStage.SEND_EMAIL]: isSendEmailError,
      [ForgotPasswordStage.VERIFY_CODE]: isSendVerifyCodeError,
      [ForgotPasswordStage.SET_PASSWORD]: isSetPasswordError,
    }),
    [isSetPasswordError, isSendEmailError, isSendVerifyCodeError],
  );

  const getStageError = useCallback(() => {
    if (stage === ForgotPasswordStage.SEND_EMAIL) {
      return sendEmailError as Nullable<StageError>;
    }
    if (stage === ForgotPasswordStage.VERIFY_CODE) {
      return sendVerifyCodeError as Nullable<StageError>;
    }
    return sendSetPasswordError as Nullable<StageError>;
  }, [stage, sendEmailError, sendVerifyCodeError, sendSetPasswordError]);

  useEffect(() => {
    if (successStatuses[stage]) {
      if (stages[stage].nextStage) {
        setStage(stages[stage].nextStage as ForgotPasswordStage);
      } else {
        redirect((redirectPaths) => redirectPaths.login);
      }

      dispatch(
        createAlert({
          variant: 'success',
          message: stages[stage].stageSuccessMessage,
        }),
      );
    }
  }, [dispatch, redirect, stage, successStatuses]);

  useEffect(() => {
    const getErrorMessage = (stageError: Nullable<StageError>) => {
      if (!stageError || !stageError.data) {
        return stages[stage].stageErrorMessage;
      }

      if (typeof stageError.data.detail === 'string') {
        if (!stageError.data.detail || stageError.data.detail.startsWith('Request failed')) {
          return stages[stage].stageErrorMessage;
        }
        return stageError.data.detail;
      }
      return stageError.data?.detail?.[0] || dictionary.errorCommon;
    };

    if (errorStatuses[stage]) {
      const stageError = getStageError();

      dispatch(
        createAlert({
          variant: 'error',
          message: getErrorMessage(stageError),
        }),
      );
    }
  }, [dispatch, stage, errorStatuses, getStageError]);

  const fields = useMemo(() => [stages[stage].field], [stage]);

  const onSubmit: SubmitHandler<Omit<SetPasswordDto, 'code'> & { code: string }> = (formValues) => {
    const { email, code: stringCode, password } = formValues;

    const code = parseInt(stringCode, 10);

    if (stage === ForgotPasswordStage.SEND_EMAIL) {
      sendVerificationEmail(email);
      return;
    }

    if (stage === ForgotPasswordStage.VERIFY_CODE) {
      sendVerifyCode({ email: emailPrefilled || email, code });
      return;
    }

    setPassword({ email: emailPrefilled || email, code, password });
  };

  return (
    <>
      <Typography component="h1" variant="h6" pb={2}>
        Lost password
      </Typography>
      {emailPrefilled && stage === ForgotPasswordStage.VERIFY_CODE && (
        <Box py={2}>
          <Typography textAlign="center">
            Please enter the verification code sent to <strong>{emailPrefilled}</strong>
          </Typography>
        </Box>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        noValidate
        maxWidth={280}
        mt={1}
      >
        <BasicForm
          columns={1}
          submitBtnLabel={submitBtnLabel}
          watch={watch}
          register={register}
          fields={fields}
          formState={formState}
        />
      </Box>
      <AppLink to={paths.login}>Go back to login</AppLink>
    </>
  );
};

export default ForgotPassword;
