import { ApiError } from '@appTypes/api/error';
import {
  ActionType,
  DefaultFilters,
  RequestSuccessActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import { SiteSimpleDto } from '@appTypes/models/site.dto';

export enum SitesNonFilteredActionsType {
  REQUEST_SITES_NON_FILTERED = 'REQUEST_SITES_NON_FILTERED',
  REQUEST_SITES_NON_FILTERED_SUCCESS = 'REQUEST_SITES_NON_FILTERED_SUCCESS',
  REQUEST_SITES_NON_FILTERED_FAILED = 'REQUEST_SITES_NON_FILTERED_FAILED',
  FETCHED_SITES_NON_FILTERED = 'FETCHED_SITES_NON_FILTERED',
  UPDATE_SITES_NON_FILTERED_PAGES = 'UPDATE_SITES_NON_FILTERED_PAGES',
}

export type RequestSitesNonFiltered =
  ActionType<SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED>;

export type RequestSitesNonFilteredSuccess = RequestSuccessActionType<
  SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED_SUCCESS,
  SiteSimpleDto
>;

export type FetchedSitesNonFiltered = ActionType<
  SitesNonFilteredActionsType.FETCHED_SITES_NON_FILTERED,
  DefaultFilters
>;

export type RequestSitesNonFilteredFailed = ActionType<
  SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED_FAILED,
  ApiError
>;

export type UpdateSitesNonFilteredPages = UpdateFilterActionType<
  SitesNonFilteredActionsType.UPDATE_SITES_NON_FILTERED_PAGES,
  DefaultFilters
>;
