import { NotificationDto } from '@appTypes/models/notification.dto';
import { CenterX } from '@components/LayoutUtils';
import { RenderDateCell } from '@components/XDataGrid/RenderDateCell';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { GridCellParams } from '@mui/x-data-grid';

type NotificationsListCellParams = GridCellParams<string, NotificationDto>;

export type NotificationFilterValues = string | boolean | undefined | number | null;

const dataGridColumns = [
  {
    field: 'site',
    headerName: 'Site',
    flex: 1,
    renderCell: ({
      row: {
        site: { name },
      },
    }: NotificationsListCellParams) => (
      <CenterX gap={1}>
        <span>{name}</span>
      </CenterX>
    ),
  },
  { field: 'device', headerName: 'Device', flex: 1.2 },
  { field: 'message', headerName: 'Notification', flex: 1.5 },
  {
    field: 'date',
    headerName: 'Date & time',
    maxWidth: 300,
    minWidth: 300,
    renderCell: RenderDateCell,
  },
].map((column) => ({
  sortable: false,
  minWidth: 130,
  ...column,
}));

type NotificationsListProps = {
  items: NotificationDto[];
  itemsCount: number;
  loading: boolean;
  filters: Record<string, NotificationFilterValues>;
  setFilters: (filterName: string, filterValue: NotificationFilterValues) => void;
};

export const NotificationsList = ({
  items,
  itemsCount,
  loading,
  filters,
  setFilters,
}: NotificationsListProps) => (
  <XDataGrid<NotificationDto>
    loading={loading}
    page={filters?.page as number}
    itemsCount={itemsCount}
    items={items}
    columns={dataGridColumns}
    onPaginationChange={(p) => setFilters('page', p)}
  />
);
