import { useGetAlarmsQuery } from '@apiRtk/alarms';
import { Alarm } from '@appTypes/models/alarm.dto';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { RenderDateCell } from '@components/XDataGrid/RenderDateCell';
import { dictionary } from '@settings/dictionary';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import ActivityIndicator from './ActivityIndicator';

const createColumns = (): ColumnDef<Alarm>[] => [
  {
    header: 'Site',
    accessorKey: 'site',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1 }}>
        <CenterX gap={1}>
          <ActivityIndicator active={row.original.active} />
          <span>{row.original.site.name}</span>
        </CenterX>
      </div>
    ),
  },
  {
    header: 'Device',
    accessorKey: 'device',
    enableSorting: false,
    cell: ({ row }) => <div style={{ flex: 1.2 }}>{row.original.device}</div>,
  },
  {
    header: 'Message',
    accessorKey: 'message',
    enableSorting: false,
    cell: ({ row }) => <div style={{ flex: 1.5 }}>{row.original.message}</div>,
  },
  {
    header: 'Date & time',
    accessorKey: 'first_occurrence',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1.2 }}>
        {RenderDateCell({
          // @ts-ignore
          row: {
            start: row.original.first_occurrence,
            end: row.original.last_occurrence,
          },
        })}
      </div>
    ),
  },
];

export type AlarmsListProps = {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const AlarmsList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: AlarmsListProps) => {
  const columns = createColumns();

  const { data, isError, refetch, isLoading, isFetching } = useGetAlarmsQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>{dictionary.errorNoData}</AlertWithRefetch>
      )}
      {data && !isLoading && !isFetching && (
        <TanStackTable<Alarm>
          columns={columns}
          rows={data.alarms || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
