import { useEffect } from 'react';
import { useGetSitesAllQuery } from '@apiRtk/sites';
import { useRedirect } from '@hooks/useRedirect';

const useRedirectToFirstSite = () => {
  const { data, isLoading } = useGetSitesAllQuery();
  const redirect = useRedirect();
  useEffect(() => {
    const shouldRedirect = !isLoading && data?.sites?.length === 1;
    if (shouldRedirect) {
      redirect((paths) => paths.siteOverview(data.sites[0].id));
    }
  }, [data, isLoading, redirect]);
};

export default useRedirectToFirstSite;
