import { FEATURE_FLAGS } from '@appTypes/common';
import { HistoricalDataApiParams, HistoricalDataDto } from '@appTypes/models/historicalData.dto';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { SECONDS_MINUTE } from '@settings/constants';
import { getApiDatesQueryParams } from '@utils/charts/getApiDatesQueryParams';
import { createParams } from '@utils/utils';
import { coreApi } from './core';

const dropCalculatorFlag = isFeatureFlagEnabled(FEATURE_FLAGS.DROP_CALCULATOR)
  ? '&drop_calculator=true'
  : '';

export const historicalDataApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getHistoricalDataForExport: builder.query<HistoricalDataDto, HistoricalDataApiParams>({
      query: ({ id, deviceId, startDate, endDate, sourceType, groupBy, ...params }) =>
        `historical-data/${id}?${createParams({
          device_id: deviceId,
          start: startDate,
          end: endDate,
          source_type: deviceId.includes('source') ? sourceType : undefined,
          group_by: groupBy,
          ...params,
        })}`,
      keepUnusedDataFor: SECONDS_MINUTE,
    }),
    getHistoricalData: builder.query<
      HistoricalDataDto,
      Omit<HistoricalDataApiParams, 'startDate' | 'endDate'> & { startDate: Date; endDate: Date }
    >({
      query: ({ id, deviceId, startDate, endDate, sourceType, groupBy, ...params }) =>
        `historical-data/${id}?${createParams({
          device_id: deviceId,
          source_type: deviceId.includes('source') ? sourceType : undefined,
          ...getApiDatesQueryParams({ startDate, endDate }),
          ...params,
        })}${dropCalculatorFlag}`,
      keepUnusedDataFor: SECONDS_MINUTE,
    }),
  }),
  overrideExisting: false,
});

export const { useGetHistoricalDataQuery, useLazyGetHistoricalDataForExportQuery } =
  historicalDataApi;
