import { SiteIdName } from '@appTypes/models/site.dto';
import { storage } from './localStorage';

const LAST_VISITED_SITES_KEY = 'lastVisitedSites';

type LocalStoredSite = {
  index?: number;
  site: SiteIdName;
};

export interface LastVisitedSitesService {
  getLastVisitedSites: () => SiteIdName[];
  addSite: (site: SiteIdName) => void;
  renameSite: (siteId: number, newName: string) => void;
}

export const getLastVisitedSitesService = (maximumCapacity: number): LastVisitedSitesService => {
  const getStoredSites = (): LocalStoredSite[] => {
    const storedSites = storage.get<LocalStoredSite[]>(LAST_VISITED_SITES_KEY);
    return storedSites !== null ? storedSites : [];
  };

  const getLastVisitedSites = (): SiteIdName[] => {
    const storedSites = getStoredSites();
    return storedSites.map((site) => site.site).reverse();
  };

  const getNextIndex = () => {
    const storedSites = getStoredSites();
    if (storedSites.length === 0) {
      return 1;
    }
    return Math.max(...storedSites.map((site) => site.index || 0)) + 1;
  };

  const removeSiteWithSiteId = (siteId: number, storedSites: LocalStoredSite[]) =>
    storedSites.filter((site) => site.site.id !== siteId);

  const removeOldestSite = (storedSites: LocalStoredSite[]) =>
    storedSites.filter(
      (site1) => site1.index !== Math.min(...storedSites.map((site2) => site2.index || 0)),
    );

  const renameSiteWithSiteId = (siteId: number, newName: string, storedSites: LocalStoredSite[]) =>
    storedSites.map((storedSite) =>
      storedSite.site.id === siteId
        ? { ...storedSite, site: { ...storedSite.site, name: newName } }
        : storedSite,
    );

  const addSite = (site: SiteIdName) => {
    const index = getNextIndex();
    const storedSite: LocalStoredSite = { index, site };
    let storedSites = getStoredSites();

    storedSites = removeSiteWithSiteId(site.id, storedSites);

    if (storedSites.length >= maximumCapacity) {
      storedSites = removeOldestSite(storedSites);
    }

    storedSites.push(storedSite);
    storage.set(LAST_VISITED_SITES_KEY, storedSites);
    dispatchEvent(new Event('last-sites-changed'));
  };

  const renameSite = (siteId: number, newName: string) => {
    let storedSites = getStoredSites();

    storedSites = renameSiteWithSiteId(siteId, newName, storedSites);

    storage.set(LAST_VISITED_SITES_KEY, storedSites);
    dispatchEvent(new Event('last-sites-changed'));
  };

  return { getLastVisitedSites, addSite, renameSite };
};
