import { PropsWithChildren } from 'react';
import { Flex } from '@components/LayoutUtils';
import { Box } from '@mui/material';
import MainMenu from './MainMenu/MainMenu';
import { Navbar } from './Navbar/Navbar';

export const AuthenticatedWrapper = ({ children }: PropsWithChildren) => (
  <Flex data-testid="auth-wrapper">
    <MainMenu>
      <Navbar />
    </MainMenu>
    <Box px={6} py={4} flexGrow={1} overflow="hidden">
      {children}
    </Box>
  </Flex>
);
