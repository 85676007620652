import { StateFilter } from '@appTypes/helpers/filters';
import { ActiveTabFilter } from '@components/ActiveTabFilter';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { SitesSelect } from '@components/SitesSelect/SitesSelect';
import { BusinessSharp } from '@mui/icons-material';
import { NotificationFilterValues } from './NotificationsList';

type ToolbarProps = {
  filters: Record<string, NotificationFilterValues>;
  setFilters: (filterName: string, filterValue: NotificationFilterValues) => void;
  noSiteFilter?: boolean;
};

export const Toolbar = ({ filters, setFilters, noSiteFilter }: ToolbarProps) => {
  const { state, site_id, search_string } = filters;

  const setStateFilter = (stateFilter: StateFilter) => {
    setFilters('state', stateFilter);
  };

  const getFilterValueFromState = (): StateFilter =>
    (Object.values(StateFilter).find((value) => value === state) as StateFilter) || StateFilter.ALL;

  return (
    <CenterX
      justifyContent="space-between"
      my={2}
      gap={2}
      flexDirection={{ xs: 'column', lg: 'row' }}
    >
      <CenterX width="100%" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
        {!noSiteFilter && (
          <SitesSelect
            data-testid="sites-select"
            value={(site_id as number) || null}
            emptyValueContent={
              <>
                <BusinessSharp />
                Your sites
              </>
            }
            onValueChange={(siteId) => setFilters('site_id', siteId)}
            formControlProps={{ sx: { minWidth: 180, width: '100%' } }}
            sx={{ '& .MuiSelect-select': { py: 1.2 } }}
          />
        )}
        <SearchInput
          size="small"
          value={(search_string as string) || ''}
          onValueChange={(value) => {
            setFilters('search_string', value);
          }}
          sx={{ maxWidth: 300 }}
          onValueChangeDependencies={[setFilters]}
        />
      </CenterX>
      <ActiveTabFilter value={getFilterValueFromState()} onValueChange={setStateFilter} />
    </CenterX>
  );
};
