import { ErrorBoundary } from '@sentry/react';

const LocalErrorBoundary = ({
  children,
  fallback,
}: React.PropsWithChildren<{
  fallback: React.ComponentProps<typeof ErrorBoundary>['fallback'];
}>) => (
  <ErrorBoundary
    fallback={fallback}
    beforeCapture={(scope) => {
      scope.setLevel('error');
    }}
  >
    {children}
  </ErrorBoundary>
);

export default LocalErrorBoundary;
