import { ActionCreator } from '@appTypes/helpers/redux';
import {
  SitesNonFilteredActionsType,
  FetchedSitesNonFiltered,
  RequestSitesNonFilteredFailed,
  RequestSitesNonFiltered,
  RequestSitesNonFilteredSuccess,
  UpdateSitesNonFilteredPages,
} from './actionTypes';

export const requestSitesNonFiltered: ActionCreator<RequestSitesNonFiltered> = () => ({
  type: SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED,
});

export const requestSitesNonFilteredSuccess: ActionCreator<RequestSitesNonFilteredSuccess> = (
  payload,
) => ({
  type: SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED_SUCCESS,
  payload,
});

export const requestSitesNonFilteredFailed: ActionCreator<RequestSitesNonFilteredFailed> = (
  payload,
) => ({
  type: SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED_FAILED,
  payload,
});

export const fetchedSitesNonFiltered: ActionCreator<FetchedSitesNonFiltered> = ({
  page = 1,
  pageSize = 5,
}) => ({
  type: SitesNonFilteredActionsType.FETCHED_SITES_NON_FILTERED,
  payload: { page, pageSize, search: '' },
});

export type SitesNonFilteredAction =
  | RequestSitesNonFiltered
  | RequestSitesNonFilteredSuccess
  | RequestSitesNonFilteredFailed
  | FetchedSitesNonFiltered
  | UpdateSitesNonFilteredPages;
