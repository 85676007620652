import { useSearchParams } from 'react-router-dom';

type DefaultType = string | number | boolean | Array<string | number | boolean> | null | undefined;

type SearchParamStateType = {
  type: 'string' | 'number' | 'boolean';
  default: DefaultType;
  multiple?: boolean;
};

const removeNullUndefinedKeys = <T extends Record<string, any>>(obj: T): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== null && value !== undefined),
  ) as Partial<T>;

export type SearchParamsStateType = Record<string, SearchParamStateType>;

const paramToBool = (
  paramName: string,
  paramDefinition: SearchParamStateType,
  searchParams: URLSearchParams,
) => {
  const paramValue = searchParams.get(paramName);
  if (paramValue === 'true' || paramValue === '1' || paramValue === '') return true;
  if (paramValue === 'false' || paramValue === '0') return false;

  return paramDefinition.default;
};

const paramToValue = (
  paramName: string,
  paramDefinition: SearchParamStateType,
  searchParams: URLSearchParams,
) => {
  if (paramDefinition.multiple) {
    const paramValue = searchParams.getAll(paramName);
    if (paramValue.length > 0) {
      return paramDefinition.type === 'number'
        ? paramValue.map((value) => Number(value))
        : paramValue;
    }
  } else {
    const paramValue = searchParams.get(paramName);
    if (paramValue) {
      return paramDefinition.type === 'number' ? Number(paramValue) : paramValue;
    }
  }
  return paramDefinition.default;
};

const getValues = (paramsDefinition: SearchParamsStateType, searchParams: URLSearchParams) =>
  Object.entries(paramsDefinition).reduce(
    (values, [paramName, paramDefinition]) => {
      values[paramName] =
        paramDefinition.type === 'boolean'
          ? paramToBool(paramName, paramDefinition, searchParams)
          : paramToValue(paramName, paramDefinition, searchParams);
      return values;
    },
    {} as Record<string, any>,
  );

const getAllCurrentParams = (searchParams: URLSearchParams) => {
  const allUrlParams: Record<string, any> = {};
  searchParams.forEach((value, key) => {
    if (allUrlParams[key]) {
      if (Array.isArray(allUrlParams[key])) {
        allUrlParams[key].push(value);
      } else {
        allUrlParams[key] = [allUrlParams[key], value];
      }
    } else {
      allUrlParams[key] = value;
    }
  });
  return allUrlParams;
};

export const useSearchParamsAsState = (paramsDefinition: SearchParamsStateType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const values = getValues(paramsDefinition, searchParams);

  const setValues = (newValues: Record<string, any>) => {
    const currentParams = getAllCurrentParams(searchParams);
    const filteredNewValues = removeNullUndefinedKeys(newValues);
    setSearchParams({ ...currentParams, ...filteredNewValues });
  };

  const removeValue = (key: string) => {
    const currentParams = getAllCurrentParams(searchParams);
    const { [key]: omit, ...filteredParams } = currentParams;

    setSearchParams(filteredParams);
  };

  return [values, setValues, removeValue] as const;
};
