import { useMemo } from 'react';
import { useGetHistoricalDataQuery } from '@apiRtk/historicalData';
import { HistoricalDataFetchedParams } from '@appTypes/models/historicalData.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import Chart, { ChartProps } from '@components/Chart/Chart';
import CONFIG from '@config';
import _ from 'lodash';
import { TimeSpan } from '../Chart/types';

type HistoricalDataChartProps = {
  id: HistoricalDataFetchedParams['id'];
  timeSpan: TimeSpan;
  graphConfigId: string;
  deviceId: string;
  graphId: string;
} & Required<Pick<ChartProps, 'graphConfig'>>;

export const HistoricalDataChart = ({
  id,
  timeSpan,
  deviceId,
  graphId,
  graphConfigId,
  graphConfig,
}: HistoricalDataChartProps) => {
  const { startDate, endDate } = timeSpan;

  const chartUid = useMemo(() => _.uniqueId(), []); // TODO: Replace by unique chart id obtained from backend when ready

  const params = {
    id,
    deviceId,
    startDate,
    endDate,
    limit: CONFIG.GRAPHS.DATA_LIMIT,
    sourceType: graphId,
  };

  const { refetch, isError, isLoading, isFetching, isSuccess, data } =
    useGetHistoricalDataQuery(params);

  const datapoints = data?.data;

  if (isError) {
    return (
      <AlertWithRefetch onRetryClick={refetch}>
        An error occurred while loading data for this graph
      </AlertWithRefetch>
    );
  }

  if (!isLoading && !isFetching && isSuccess && datapoints?.length === 0) {
    return (
      <AlertWithRefetch onRetryClick={refetch} severity="info">
        No data available
      </AlertWithRefetch>
    );
  }

  return (
    <Chart
      chartUid={chartUid}
      datapoints={datapoints || []}
      outages={data?.outages || []}
      loading={isLoading}
      isFetching={isFetching}
      graphConfigId={graphConfigId}
      graphConfig={graphConfig}
      refetch={refetch}
    />
  );
};
