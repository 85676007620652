import ReactApexChart from '@components/CustomReactApexcharts';
import { usePalette } from '@hooks/usePalette';
import { Box, Palette } from '@mui/material';
import { ApexOptions } from 'apexcharts';

const createOptions = (colors: (string | undefined)[], themePalette: Palette): ApexOptions => ({
  chart: {
    sparkline: {
      enabled: false,
    },
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  grid: {
    show: false,
    padding: {
      top: -30,
      right: 0,
      bottom: -60,
      left: 0,
    },
  },
  legend: {
    show: false,
  },
  colors: [colors[0], colors[1]],
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '90%', // Adjust the percentage to control the gap size
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 3,
    colors: ['transparent'],
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      formatter(val: string) {
        return parseInt(val, 10) === 0 ? '£0' : '';
      },
      offsetY: -22,
    },
  },
  yaxis: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  annotations: {
    xaxis: [
      {
        x: 0,
        offsetY: -34,
        strokeDashArray: 0,
        borderColor: themePalette.text.secondary,
        label: {
          text: '',
          style: {
            color: themePalette.text.secondary,
          },
        },
      },
    ],
  },
});

export type BarGraphSeries = {
  name: string;
  data: number[];
}[];

type BarGraphProps = {
  series: BarGraphSeries;
  colors: (string | undefined)[];
};

const BarGraph = ({ series, colors }: BarGraphProps) => {
  const palette = usePalette();
  const options = createOptions(colors, palette);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
      <ReactApexChart options={options} series={series} height={80} type="bar" width="100%" />
    </Box>
  );
};

export default BarGraph;
