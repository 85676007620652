import { PropsWithChildren } from 'react';
import { CenterX, Flex } from '@components/LayoutUtils';
import { Check, Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type ConfirmModalProps = PropsWithChildren<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  confirmButtonCaption?: string;
  cancelButtonCaption?: string;
  noCancelButton?: boolean;
  submitDisabled?: boolean;
}>;

const FormModal = ({
  open,
  setOpen,
  children,
  dialogTitle,
  onSubmit,
  onCancel,
  confirmButtonCaption,
  cancelButtonCaption,
  noCancelButton,
  submitDisabled,
}: ConfirmModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onSubmit?.();
  };

  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} sx={{ zIndex: 1300 }} onClose={handleClose}>
      {dialogTitle ? (
        <>
          <CenterX pl={3} pr={1} py={1} justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              {dialogTitle}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </CenterX>
          <Divider />
        </>
      ) : null}
      <Box px={3} py={2}>
        {children}
        <Flex p={0} pt={2} gap={1} justifyContent="flex-end">
          <Button
            data-testid="form-submit"
            type="submit"
            onClick={handleConfirm}
            autoFocus
            startIcon={<Check />}
            disabled={submitDisabled}
          >
            {confirmButtonCaption || 'Confirm'}
          </Button>
          {noCancelButton ? null : (
            <Button onClick={handleCancel} variant="secondaryFade" startIcon={<Close />}>
              {cancelButtonCaption || 'Cancel'}
            </Button>
          )}
        </Flex>
      </Box>
    </Dialog>
  );
};

export default FormModal;
