import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { SiteFilters } from '@appTypes/sites/filters';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { SitesAction } from '../sites/actionCreators';
import { SitesNonFilteredAction } from './actionCreators';
import { SitesNonFilteredActionsType } from './actionTypes';

const initialState = {
  sitesNonFiltered: null as Nullable<WPage<SiteSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  filters: {
    page: 1,
    pageSize: 10,
    descending: false,
    orderBy: 'name',
  } as SiteFilters,
};

export type SiteNonFilteredStateType = typeof initialState;

export const sitesNonFilteredReducer = (
  state = initialState,
  action: SitesNonFilteredAction | SitesAction,
): SiteNonFilteredStateType => {
  const reducerHandler = createReducerHandler<SiteNonFilteredStateType, SiteSimpleDto>(
    'sitesNonFiltered',
    state,
  );

  switch (action.type) {
    case SitesNonFilteredActionsType.UPDATE_SITES_NON_FILTERED_PAGES:
      return reducerHandler.updateFilter(action.payload);
    case SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED:
      return reducerHandler.request();
    case SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED_SUCCESS:
      return reducerHandler.requestSuccess(action.payload);
    case SitesNonFilteredActionsType.REQUEST_SITES_NON_FILTERED_FAILED:
      return reducerHandler.requestFailed(action.payload);
    default:
      return state;
  }
};
