import { HistoricalDataDto } from '@appTypes/models/historicalData.dto';

export enum TimeUnit {
  MINUTES,
  HOURS,
  DAYS,
  TODAY,
  YESTERDAY,
  THIS_WEEK,
  THIS_MONTH,
}
export type DateTimePickerDate = Date;

export type TimeSpan = {
  startDate: DateTimePickerDate;
  endDate: DateTimePickerDate;
};

export type OnDateApplyClick = (startVal: DateTimePickerDate, endVal: DateTimePickerDate) => void;

export type DateSpanProps = {
  initStartVal: DateTimePickerDate;
  initEndVal: DateTimePickerDate;
  onApplyClick: OnDateApplyClick;
  displayFromInput?: boolean;
};

/* Charts */
export type GraphConfig = {
  name: string;
  label: string;
  unit: string;
  unitSymbol: string;
  min: Nullable<number>;
  max: Nullable<number>;
  leftSide: boolean;
};

export type UseChartOptionsParams = {
  outages: HistoricalDataDto['outages'];
  errorOccurred?: boolean;
  showToolbar: boolean;
  graphConfig: GraphConfig[];
  onDateRangeSelect?: (arg: { startDate: Date; endDate: Date }) => void;
  chartId: string;
};

export type ApexGlobalSeriesData = {
  y: number | undefined;
};

export type ApexSeries = {
  name?: string;
  type?: string;
  color?: string;
  group?: string;
  data: ApexGlobalSeriesData[];
};
