import { useState } from 'react';
import { ArrayElement } from '@appTypes/helpers/arrayElement';
import { SiteDto } from '@appTypes/models/site.dto';
import { AddButton } from '@components/Buttons/AddButton';
import { SearchInput } from '@components/SearchInput';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { useRedirect } from '@hooks/useRedirect';
import { Box, Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { normaliseString } from '@utils/utils';

type ECDsGridCellProps = GridCellParams<string, ArrayElement<SiteDto['imps']>>;

const CellWithRedirectButton = ({ row: { name, id } }: ECDsGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{name}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationEcd(id))}>
        ECD Detail
      </Button>
    </Box>
  );
};

const columns = [
  {
    field: 'id',
    headerName: 'ECD ID',
    flex: 0.8,
  },
  {
    field: 'name',
    headerName: 'ECD Name',
    minWidth: 220,
    flex: 1,
    sortable: false,
    renderCell: CellWithRedirectButton,
  },
];

interface EcdListProps {
  siteData?: SiteDto;
  onAddRelatedECDs: () => void;
}

const EcdList = ({ siteData, onAddRelatedECDs }: EcdListProps) => {
  const [searchText, setSearchText] = useState('');

  const items =
    siteData?.imps.filter((ecd) =>
      normaliseString([ecd.id, ecd.name].join()).includes(normaliseString(searchText)),
    ) || [];

  return (
    <>
      <AddButton sx={{ my: 2 }} onClick={onAddRelatedECDs}>
        Add related ECDs
      </AddButton>
      <SearchInput value={searchText} onValueChange={setSearchText} sx={{ mb: 2 }} />
      <XDataGrid items={items} itemsCount={items.length} columns={columns} />
    </>
  );
};

export default EcdList;
