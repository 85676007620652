import { memo, useState } from 'react';
import { HistoricalDataDto, HistoricalDatapoints } from '@appTypes/models/historicalData.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import LocalErrorBoundary from '@components/LocalErrorBoundary/LocalErrorBoundary';
import { GraphLoader } from '@components/Spinner';
import { Box, styled } from '@mui/material';
import _ from 'lodash';
import ReactApexChart from '../CustomReactApexcharts';
import ChartLegend from './ChartLegend/ChartLegend';
import { useChartOptions } from './hooks/useChartOptions';
import { useChartSeries } from './hooks/useChartSeries';
import { GraphConfig, UseChartOptionsParams } from './types';

export type ChartProps = {
  datapoints: HistoricalDatapoints;
  outages: HistoricalDataDto['outages'];
  graphConfigId: string;
  refetch: () => void;
  chartUid: string;
  loading?: boolean;
  isFetching?: boolean;
  showToolbar?: boolean;
  graphConfig?: GraphConfig[];
} & Pick<UseChartOptionsParams, 'onDateRangeSelect'>;

const GraphWrapper = styled('div')<{ visible?: boolean }>`
  transition: 500ms opacity linear;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

const Chart = ({
  datapoints,
  loading,
  graphConfigId,
  showToolbar = false,
  graphConfig = [],
  isFetching,
  chartUid,
  outages,
  refetch,
}: ChartProps) => {
  const series = useChartSeries(datapoints, graphConfig);
  const [chartReady, setChartReady] = useState(false);

  const seriesNames = series.map((s) => s.name);

  // Split configs for series which have data and those were dropped because of no data
  const [graphConfigFiltered, graphConfigNoData] = _.partition(graphConfig, (config) =>
    seriesNames.includes(config.name),
  );

  const { options } = useChartOptions({
    outages,
    showToolbar,
    graphConfig: graphConfigFiltered,
    chartId: chartUid,
  });

  const handleReady = (state: boolean) => {
    if (state) {
      setChartReady(true);
    }
  };

  const readyToShow = !loading && chartReady && series.length > 0;

  const fallbackAlert = (
    <AlertWithRefetch onRetryClick={refetch}>
      An error occurred while drawing the graph. If the problem persists, contact the application
      vendor.
    </AlertWithRefetch>
  );

  return (
    <LocalErrorBoundary fallback={fallbackAlert}>
      <Box position="relative">
        {(isFetching || loading) && <GraphLoader />}
        <GraphWrapper visible={readyToShow}>
          <ReactApexChart
            options={options}
            series={loading ? [] : series}
            type="line"
            height={300}
            width="100%"
            onReadyChange={handleReady}
          />
          {readyToShow && (
            <ChartLegend
              outages={outages}
              options={options}
              chartId={chartUid}
              graphConfigId={graphConfigId}
              graphConfig={graphConfigFiltered}
              graphConfigNoData={graphConfigNoData}
              seriesNames={seriesNames}
              series={series}
            />
          )}
        </GraphWrapper>
      </Box>
    </LocalErrorBoundary>
  );
};

export default memo(Chart);
