import { ArrayElement } from '@appTypes/helpers/arrayElement';
import { OrganizationDto } from '@appTypes/models/organization.dto';
import { UserRole } from '@appTypes/models/user.dto';
import { useRedirect } from '@hooks';
import { Box, Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { createFullName } from '@utils/data/createFullName';
import {
  OrganizationRelationsGrid,
  OrganizationRelationsGridProps,
} from './OrganizationRelationsGrid';

type UsersGridCellProps = GridCellParams<string, ArrayElement<OrganizationDto['users']>>;

const CellWithRedirectButton = ({
  row: {
    role: { name: roleName },
    id,
  },
}: UsersGridCellProps) => {
  const redirect = useRedirect();

  const roleDisplay = (() => {
    if (roleName === UserRole.ADMIN) {
      return 'manager';
    }

    if (roleName === UserRole.USER) {
      return 'technician';
    }

    return roleName;
  })();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{roleDisplay}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationUser(id))}>
        User detail
      </Button>
    </Box>
  );
};

const columns = [
  {
    field: 'full_name',
    headerName: 'Full Name',
    flex: 1,
    sortable: false,
    renderCell: ({ row: { first_name, last_name } }: UsersGridCellProps) =>
      createFullName(first_name, last_name),
  },
  {
    field: 'email',
    headerName: 'E-mail',
    flex: 1,
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 220,
    flex: 1,
    sortable: false,
    renderCell: CellWithRedirectButton,
  },
];

export const UsersRelations = (
  props: Pick<OrganizationRelationsGridProps, 'searchTxt' | 'setSearchTxt'>,
) => (
  <OrganizationRelationsGrid<ArrayElement<OrganizationDto['users']>>
    itemsField="users"
    columns={columns}
    searchFields={['first_name', 'last_name', 'email']}
    {...props}
  />
);
