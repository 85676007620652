interface ConditionalWrapperProps {
  Wrapper: React.ComponentType<any>;
  condition: boolean;
}

const ConditionalWrapper = ({
  Wrapper,
  condition,
  children,
}: React.PropsWithChildren<ConditionalWrapperProps>) =>
  condition ? <Wrapper>{children}</Wrapper> : children;

export default ConditionalWrapper;
