import { useState } from 'react';
import { ArrayElement } from '@appTypes/helpers/arrayElement';
import { OrganizationDto } from '@appTypes/models/organization.dto';
import { SiteDto } from '@appTypes/models/site.dto';
import { UserRole } from '@appTypes/models/user.dto';
import { AddButton } from '@components/Buttons/AddButton';
import { SearchInput } from '@components/SearchInput';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { useRedirect } from '@hooks/useRedirect';
import { Box, Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { createFullName } from '@utils/data/createFullName';
import { normaliseString } from '@utils/utils';

type UsersGridCellProps = GridCellParams<string, ArrayElement<OrganizationDto['users']>>;

const CellWithRedirectButton = ({
  row: {
    role: { name: roleName },
    id,
  },
}: UsersGridCellProps) => {
  const redirect = useRedirect();

  const roleDisplay = (() => {
    if (roleName === UserRole.ADMIN) {
      return 'manager';
    }

    if (roleName === UserRole.USER) {
      return 'technician';
    }

    return roleName;
  })();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{roleDisplay}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationUser(id))}>
        User detail
      </Button>
    </Box>
  );
};

const columns = [
  {
    field: 'full_name',
    headerName: 'Full Name',
    minWidth: 150,
    flex: 0.8,
    sortable: false,
    renderCell: ({ row: { first_name, last_name } }: UsersGridCellProps) =>
      createFullName(first_name, last_name),
  },
  {
    field: 'email',
    headerName: 'E-mail',
    flex: 0.8,
  },
  {
    field: 'role',
    headerName: 'Role',
    minWidth: 220,
    flex: 1,
    sortable: false,
    renderCell: CellWithRedirectButton,
  },
];

interface UserListProps {
  siteData?: SiteDto;
  onAddRelatedUser: () => void;
}

const UserList = ({ siteData, onAddRelatedUser }: UserListProps) => {
  const [searchText, setSearchText] = useState('');

  const items =
    siteData?.users.filter((user) =>
      normaliseString([user.first_name, user.last_name, user.email].join()).includes(
        normaliseString(searchText),
      ),
    ) || [];

  return (
    <>
      <AddButton sx={{ my: 2 }} onClick={onAddRelatedUser}>
        Add related user
      </AddButton>
      <SearchInput value={searchText} onValueChange={setSearchText} sx={{ mb: 2 }} />
      <XDataGrid items={items} itemsCount={items.length} columns={columns} />
    </>
  );
};

export default UserList;
