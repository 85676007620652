import { ActionCreator } from '@appTypes/helpers/redux';
import {
  OrganizationsNonFilteredActionsType,
  FetchedOrganizationsNonFiltered,
  RequestOrganizationsNonFilteredFailed,
  RequestOrganizationsNonFiltered,
  RequestOrganizationsNonFilteredSuccess,
  UpdateOrganizationsNonFilteredPages,
} from './actionTypes';

export const requestOrganizationsNonFiltered: ActionCreator<
  RequestOrganizationsNonFiltered
> = () => ({
  type: OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED,
});

export const requestOrganizationsNonFilteredSuccess: ActionCreator<
  RequestOrganizationsNonFilteredSuccess
> = (payload) => ({
  type: OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED_SUCCESS,
  payload,
});

export const requestOrganizationsNonFilteredFailed: ActionCreator<
  RequestOrganizationsNonFilteredFailed
> = (payload) => ({
  type: OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED_FAILED,
  payload,
});

export const fetchedOrganizationsNonFiltered: ActionCreator<FetchedOrganizationsNonFiltered> = ({
  page = 1,
  pageSize = 5,
}) => ({
  type: OrganizationsNonFilteredActionsType.FETCHED_ORGANIZATIONS_NON_FILTERED,
  payload: { page, pageSize, search: '' },
});

export type OrganizationsNonFilteredAction =
  | RequestOrganizationsNonFiltered
  | RequestOrganizationsNonFilteredSuccess
  | RequestOrganizationsNonFilteredFailed
  | FetchedOrganizationsNonFiltered
  | UpdateOrganizationsNonFilteredPages;
