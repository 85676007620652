import { useGetSitesQuery } from '@apiRtk/sites';
import { SiteDto } from '@appTypes/models/site.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { AppLink } from '@components/AppLink';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { Spinner } from '@components/Spinner';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useRedirectToFirstSite } from '@hooks/index';
import { useFiltersTanStack } from '@hooks/useFiltersTanStack';
import { FormatListNumbered, Widgets } from '@mui/icons-material';
import CONFIG from '@settings/config';
import { ColumnDef, FilterFnOption } from '@tanstack/react-table';
import { paths } from 'paths';

const columns: ColumnDef<SiteDto>[] = [
  {
    header: () => <span>Name</span>,
    accessorKey: 'name',
    filterFn: 'includesString' as FilterFnOption<SiteDto>,
    cell: ({ row }) => (
      <div style={{ flex: 0.5, display: 'flex', alignItems: 'center' }}>
        <AppLink to={paths.siteOverview(row.original.id)} noUnderline>
          <CenterX gap={1}>
            <Widgets />
            <span>{row.original.name}</span>
          </CenterX>
        </AppLink>
      </div>
    ),
  },
  {
    id: 'organization.name',
    header: () => <span>Organization</span>,
    accessorKey: 'organization.name',
    filterFn: 'includesString' as FilterFnOption<SiteDto>,
  },
  {
    header: () => 'Location',
    accessorKey: 'address.city',
    filterFn: 'includesString' as FilterFnOption<SiteDto>,
    enableSorting: false,
    cell: ({ row }) => (
      <>
        <div>
          <strong>{row.original.address.city}</strong>
        </div>
        <div>{row.original.address.country}</div>
      </>
    ),
  },
];

const Sites = () => {
  useRedirectToFirstSite();

  const {
    getFilters,
    sorting,
    searchString,
    pagination,
    setSorting,
    setSearchString,
    setPagination,
  } = useFiltersTanStack({
    pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE,
    sortingState: [
      {
        id: 'name',
        desc: false,
      },
    ],
  });

  const { data, isError, refetch, isLoading } = useGetSitesQuery(getFilters(), {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      <TitleBar mb={4} title="Your sites" IconComponent={FormatListNumbered} />
      <CenterX mb={2}>
        <SearchInput
          size="small"
          value={searchString}
          sx={{ maxWidth: 250 }}
          onValueChange={setSearchString}
        />
      </CenterX>
      {isLoading && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>
          Some error occured while getting sites, please try again later.
        </AlertWithRefetch>
      )}
      {data && !isLoading && (
        <TanStackTable<SiteDto>
          columns={columns}
          rows={data.sites || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={setSorting}
          onPaginationChange={setPagination}
        />
      )}
    </>
  );
};

export default Sites;
