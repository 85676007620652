import { DashboardsNavItem } from '@components/AppWrapper/AuthenticatedWrapper/Navbar/DashboardsNavItem/DashboardsNavItem';
import {
  SvgIconComponent,
  Notifications,
  Settings,
  FormatListNumbered,
  AccessAlarm,
} from '@mui/icons-material';
import AdminIcon from '@mui/icons-material/LocalPoliceOutlined';
import { Permission } from '@providers/PermissionProvider';
import { paths } from 'paths';

type NavItem = {
  id: string;
  title: string;
  CustomItemComponent?: () => JSX.Element;
  path?: string;
  permissions?: Permission[];
  alwaysExpanded?: boolean;
  IconComponent: SvgIconComponent;
  wattstorUserOnly?: boolean;
  items?: {
    id: string;
    title: string;
    path: string;
  }[];
};

export const navItems = [
  {
    title: 'Admin tools',
    IconComponent: AdminIcon,
    path: paths.adminTools,
    wattstorUserOnly: true,
  },
  {
    title: 'Your sites',
    IconComponent: FormatListNumbered,
    path: paths.sites,
    CustomItemComponent: DashboardsNavItem,
    alwaysExpanded: true,
  },
  {
    title: 'Alarms',
    IconComponent: AccessAlarm,
    path: paths.alarms,
  },
  {
    title: 'Notifications',
    IconComponent: Notifications,
    path: paths.notifications,
  },
  {
    title: 'Configuration',
    IconComponent: Settings,
    path: paths.configuration,
  },
].map((navItem, navItemIndex) => {
  const navItemWithId = {
    ...navItem,
    id: `${navItemIndex}`,
  };

  return navItemWithId;
}) as NavItem[];
