import { instance } from '@api/instance';
import { loginApi } from '@apiRtk/login';
import CONFIG, { ENDPOINTS_WITHOUT_AUTH } from '@config';
import { MILLIS_SECOND } from '@constants';
import { store } from '@redux/store';
import { storage } from '@services/localStorage';
import { trimSlashes } from '@utils/utils';
import jwt_decode from 'jwt-decode';
import memoize from 'memoizee';
import { setAccessTokenHeader } from './helpers';
import { authActions } from '.';

export const refreshTokenFn = async () => {
  const response = await store.dispatch(
    loginApi.endpoints.refresh.initiate(storage.get('refreshToken') as string),
  );

  if ('data' in response) {
    const { refresh_token: refreshToken, access_token: accessToken } = response.data;

    storage.set('refreshToken', refreshToken);
    setAccessTokenHeader(accessToken);

    return accessToken;
  }

  return '';
};

const memoizedRefreshToken = memoize(refreshTokenFn, {
  maxAge: CONFIG.REFRESH_TOKEN_CALL_TIMEOUT,
});

const isEndpointWithAuth = (url: string) => !ENDPOINTS_WITHOUT_AUTH.includes(trimSlashes(url));

const isTokenExpired = (token: string | null) => {
  if (!token) return true;

  const decodedToken = jwt_decode<{ exp?: number }>(token);
  const now = new Date();

  if (typeof decodedToken?.exp !== 'number') {
    return true;
  }

  return decodedToken.exp * MILLIS_SECOND < now.getTime();
};

export const initRefreshTokenInterceptor = () => {
  instance.interceptors.request.use(async (config) => {
    if (config.headers && config.url && isEndpointWithAuth(config.url)) {
      const tokenExpired = isTokenExpired(storage.get('accessToken'));

      if (tokenExpired) {
        try {
          const newAccessToken = await memoizedRefreshToken();

          config.headers.Authorization = `Bearer ${newAccessToken}`;

          return config;
        } catch {
          store.dispatch(authActions.logout());
          return Promise.reject();
        }
      }
    }

    return config;
  });
};
