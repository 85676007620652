import { useEffect } from 'react';
import { useGetSiteQuery } from '@apiRtk/sites';
import { HttpStatusCode } from '@appTypes/api/error';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { Spinner } from '@components/Spinner';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useDashboardInfo } from '@hooks';
import { MultilineChart } from '@mui/icons-material';
import { ChartToolbarProvider } from '@providers/ChartToolbarProvider';
import { selectAuthSlice } from '@redux/slices/auth';
import { getLastVisitedSitesService } from '@services/lastVisitedSites';
import { MAX_LAST_VISITED_SITES } from '@settings/config';
import { dictionary } from '@settings/dictionary';
import { useSelector } from 'react-redux';
import { Graph } from './Graph';

const DataAnalysis = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const dashboardPageConfig = {
    displayDateRangeSelect: true,
    displayFromInput: true,
  };

  const { refetch, dashboardId, error, isError, isLoading, graphsConfig, siteId } =
    useDashboardInfo();
  const lastVisitedSites = getLastVisitedSitesService(MAX_LAST_VISITED_SITES);

  const { data: siteData } = useGetSiteQuery(siteId, {
    skip: !siteId && siteId !== 0,
  });

  useEffect(() => {
    if (siteData?.name) {
      lastVisitedSites.addSite({
        id: siteData.id,
        name: siteData.name,
      });
    }
  }, [siteData, lastVisitedSites]);

  if (!graphsConfig || isLoading) {
    return <Spinner />;
  }

  if (isError) {
    const errorMessage =
      error?.status === HttpStatusCode.NOT_FOUND
        ? `Dashboard with id: "${dashboardId}" not found`
        : dictionary.errorCommon;

    return <AlertWithRefetch onRetryClick={refetch}>{errorMessage}</AlertWithRefetch>;
  }

  const currentSite = currentUser?.sites.find(({ id }) => id === siteId);
  const title = `${currentSite?.name} - Data Analysis`;

  return (
    <ChartToolbarProvider {...dashboardPageConfig}>
      {({ renderDateInputs, timeSpan }) => (
        <>
          <TitleBar title={title} IconComponent={MultilineChart} />
          {renderDateInputs()}
          {graphsConfig.map((graph) => (
            <Graph key={`${graph.id}-${graph.graph_id}`} timeSpan={timeSpan} {...graph} />
          ))}
        </>
      )}
    </ChartToolbarProvider>
  );
};

export default DataAnalysis;
