import { Alert } from '@appTypes/alerts/alert';
import _ from 'lodash';
import { AlertsAction } from './actionCreators';
import { AlertsActionsTypes } from './actionTypes';

const initialState = {
  count: 0,
  currentAlerts: {} as { [key: string]: Alert },
};

export const alertsReducer = (state = initialState, action: AlertsAction): AlertsStateType => {
  switch (action.type) {
    case AlertsActionsTypes.SHOW_ALERT: {
      const newCurrentAlerts = {
        ...state.currentAlerts,
        [action.payload.id]: action.payload,
      };

      return {
        count: Object.keys(newCurrentAlerts).length,
        currentAlerts: newCurrentAlerts,
      };
    }
    case AlertsActionsTypes.REMOVE_ALERT: {
      const newCurrentAlerts = _.omit(state.currentAlerts, action.payload);

      return {
        count: Object.keys(newCurrentAlerts).length,
        currentAlerts: newCurrentAlerts,
      };
    }
    default:
      return state;
  }
};

export type AlertsStateType = typeof initialState;
