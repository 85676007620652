import { NotificationGridCellProps } from '@appTypes/notifications/cells';
import { LocationOn } from '@mui/icons-material';
import { Box } from '@mui/material';

export const SiteCell = ({
  row: {
    site: { name },
  },
}: NotificationGridCellProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <LocationOn sx={{ mr: 1 }} />
    <span>{name}</span>
  </Box>
);
