import { StateFilter } from '@appTypes/helpers/filters';
import { ActiveTabFilter } from '@components/ActiveTabFilter';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { SitesSelect } from '@components/SitesSelect/SitesSelect';
import { BusinessSharp } from '@mui/icons-material';

interface ToolbarProps {
  siteId: Nullable<number>;
  searchString: string;
  stateFilter: StateFilter;
  onSetSiteId: (siteId: Nullable<number>) => void;
  onSearch: (searchString: string) => void;
  onSetStateFilter: (stateFilter: StateFilter) => void;
}

export const Toolbar = ({
  searchString,
  siteId,
  stateFilter,
  onSetSiteId,
  onSearch,
  onSetStateFilter,
}: ToolbarProps) => (
  <CenterX
    justifyContent="space-between"
    my={2}
    gap={2}
    flexDirection={{ xs: 'column', lg: 'row' }}
  >
    <CenterX width="100%" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
      <SitesSelect
        data-testid="sites-select"
        value={siteId}
        emptyValueContent={
          <>
            <BusinessSharp />
            Your sites
          </>
        }
        onValueChange={onSetSiteId}
        formControlProps={{ sx: { minWidth: 180, width: '100%' } }}
        sx={{ '& .MuiSelect-select': { py: 1.2 } }}
      />
      <SearchInput
        size="small"
        value={searchString}
        onValueChange={onSearch}
        sx={{ maxWidth: 300 }}
        onValueChangeDependencies={[]}
      />
    </CenterX>
    <ActiveTabFilter value={stateFilter} onValueChange={onSetStateFilter} />
  </CenterX>
);
