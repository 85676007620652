import { Box, BoxProps } from '@mui/material';
import { palette } from 'styles/palette';
import { CommonModal, CommonModalProps } from './CommonModal';

export type BoxModalProps = CommonModalProps & { boxProps?: BoxProps };

export const BoxModal = ({
  open,
  children,
  boxProps,
  ...configurationListProps
}: BoxModalProps) => (
  <CommonModal open={open} {...configurationListProps}>
    <Box
      {...(boxProps || {})}
      sx={{
        bgcolor: palette.neutral.light,
        p: 2,
        width: '100%',
        borderRadius: 4,
        maxWidth: 800,
        ...(boxProps?.sx || {}),
      }}
    >
      {open && children}
    </Box>
  </CommonModal>
);
