import { useEffect, useMemo } from 'react';
import {
  useCreateNotificationRuleMutation,
  useDeleteNotificationRuleMutation,
  useGetNotificationRuleQuery,
  useUpdateNotificationRuleMutation,
} from '@apiRtk/notificationRules';
import {
  UpdateNotificationRuleDto,
  NotificationRuleDto,
} from '@appTypes/models/notificationRule.dto';
import {
  ConditionComparisonOperator,
  CalculatorComparisonField,
  NotificationRuleDevice,
  BatteryComparisonField,
} from '@appTypes/notificationRules/fields';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { CenterX } from '@components/LayoutUtils';
import { TriggerWithConfirmModal } from '@components/Modals/ConfirmModal';
import { SitesSelect } from '@components/SitesSelect/SitesSelect';
import { Spinner } from '@components/Spinner';
import { useMutationAlerts } from '@hooks';
import {
  Check,
  Close,
  DeleteOutline,
  LocationOn,
  MailOutline,
  CalculateSharp,
} from '@mui/icons-material';
import { Drawer, Box, Typography, BoxProps, Button, TextField } from '@mui/material';
import { getSitesNonFilteredSelector } from '@redux/ducks/sitesNonFiltered/selectors';
import { getRgbaColor } from '@utils/utils';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { palette } from 'styles/palette';
import { ComparisonFieldSelectBox } from './ComparisonFieldSelectBox/ComparisonFieldSelectBox';
import { ConditionComparisonSelectBox } from './ConditionComparisonSelectBox/ConditionComparisonSelectBox';
import { ConditionValInput } from './ConditionValInput/ConditionValInput';
import { DeviceSelectBox } from './DeviceSelectBox/DeviceSelectBox';
import { IsActiveSelectBox, IsActiveSelectBoxVal } from './IsActiveSelectBox/IsActiveSelectBox';
import { LabeledInput } from './Labeled/LabeledInput';
import { LabeledSelectBox } from './Labeled/LabeledSelectBox';

interface EditRuleProps {
  ruleId: Nullable<number>;
  createRuleMode: boolean;
  handleFormClose(): void;
}

const DrawerContentWrapper = (props: BoxProps) => (
  <Box width="20vw" minWidth={230} display="grid" p={2.5} gap={2} {...props} />
);

const EditRule = ({
  rule,
  handleFormClose,
}: {
  rule?: Nullable<NotificationRuleDto>;
} & Pick<EditRuleProps, 'handleFormClose'>) => {
  const [createNotificationRule, createResult] = useCreateNotificationRuleMutation();
  const [deleteNotificationRule, deleteResult] = useDeleteNotificationRuleMutation();
  const [updateNotificationRule, updateResult] = useUpdateNotificationRuleMutation();

  useMutationAlerts({
    mutationResult: updateResult,
    actionName: 'Update notification rule',
    onSuccess() {
      handleFormClose();
    },
  });

  useMutationAlerts({
    mutationResult: createResult,
    actionName: 'Create notification rule',
    onSuccess() {
      handleFormClose();
    },
  });

  useMutationAlerts({
    mutationResult: deleteResult,
    actionName: 'Delete notification rule',
    onSuccess() {
      handleFormClose();
    },
  });

  const editMode = !!rule;

  const { sitesNonFiltered } = useSelector(getSitesNonFilteredSelector);

  const defaultValues = useMemo(
    () =>
      editMode
        ? {
            is_active: rule.is_active ? IsActiveSelectBoxVal.ACTIVE : IsActiveSelectBoxVal.INACTIVE,
            emails_list: rule.emails_list,
            site_id: rule.site.id,
            device: rule.device,
            condition_comparison_operator: rule.condition_comparison_operator,
            condition_value: `${rule.condition_value}`,
            comparison_field: rule.comparison_field,
          }
        : {
            is_active: IsActiveSelectBoxVal.ACTIVE,
            emails_list: '',
            site_id: (sitesNonFiltered && sitesNonFiltered[0]?.id) || undefined,
            device: NotificationRuleDevice.CALCULATOR,
            condition_comparison_operator: ConditionComparisonOperator.GREATER_OR_EQUAL,
            condition_value: '0.75',
            comparison_field: CalculatorComparisonField.CONSUMPTION,
          },
    [editMode, rule, sitesNonFiltered],
  );

  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = (formData: typeof defaultValues) => {
    const preparedValues = {
      ...formData,
      description: '',
      is_active: formData.is_active === IsActiveSelectBoxVal.ACTIVE,
      site_id: parseInt(formData.site_id as unknown as string, 10),
      condition_value: parseFloat(formData.condition_value),
    };

    if (editMode) {
      updateNotificationRule({
        id: rule.id,
        ...preparedValues,
      });
    } else {
      createNotificationRule(preparedValues);
    }
  };

  const handleDelete = () => {
    if (!rule) return;
    deleteNotificationRule(rule.id);
  };

  const onDeviceChange = (value: string) => {
    if (value === NotificationRuleDevice.BATTERY) {
      setValue('comparison_field', BatteryComparisonField.SOC);
    } else if (value === NotificationRuleDevice.CALCULATOR) {
      setValue('comparison_field', CalculatorComparisonField.CONSUMPTION);
    }
  };

  function registerSelectBox<TValue = string>(
    name: keyof Omit<UpdateNotificationRuleDto, 'id' | 'description'>,
  ) {
    const { onChange: formHandlerOnChange } = register(name);

    const onChange: typeof formHandlerOnChange = (e) => {
      if (name === 'device') {
        onDeviceChange(e.target.value);
      }

      return formHandlerOnChange(e);
    };

    return {
      onChange,
      name,
      value: watch(name) as unknown as TValue,
    };
  }

  return (
    <DrawerContentWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box display="grid" gridTemplateColumns="1fr auto" alignItems="center" mb={3}>
        <Typography variant="h5" color="primary" fontWeight={700}>
          {editMode ? 'Edit' : 'Add New'} Rule
        </Typography>
        <Close sx={{ cursor: 'pointer' }} color="primary" onClick={handleFormClose} />
      </Box>
      <IsActiveSelectBox {...registerSelectBox('is_active')} />
      <LabeledSelectBox
        IconComponent={LocationOn}
        label="When in site"
        selectBox={<SitesSelect {...registerSelectBox<number>('site_id')} disableEmptyValue />}
      />
      <DeviceSelectBox {...registerSelectBox('device')} />
      <ComparisonFieldSelectBox
        {...registerSelectBox('comparison_field')}
        currentDevice={watch('device')}
      />
      <ConditionComparisonSelectBox {...registerSelectBox('condition_comparison_operator')} />
      <ConditionValInput {...register('condition_value')} />
      <LabeledInput
        label="Notify by e-mail"
        IconComponent={MailOutline}
        renderedInputComponent={<TextField multiline rows={3} {...register('emails_list')} />}
      />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          justifyContent: 'space-between',
        }}
      >
        <Button size="large" startIcon={<Check />} type="submit">
          <Box sx={{ mr: 2 }}>Done</Box>
          {createResult.isLoading || (updateResult.isLoading && <Spinner size={15} />)}
        </Button>
        {editMode && (
          <TriggerWithConfirmModal
            renderTrigger={(openModal) => (
              <DeleteOutline
                color="secondary"
                sx={{
                  padding: 1.2,
                  borderRadius: '40px',
                  backgroundColor: getRgbaColor(palette.secondary.main, 0.1),
                  cursor: 'pointer',
                }}
                onClick={() => {
                  openModal();
                }}
                fontSize="small"
              />
            )}
            onConfirm={handleDelete}
            dialogTitle="Delete rule"
          >
            <Typography variant="subtitle1">
              Do you really want to delete following rule?
            </Typography>
            <CenterX p={1} gap={0.5}>
              <LocationOn color="secondary" />
              <Typography variant="caption">{rule?.site.name}</Typography>
            </CenterX>
            <CenterX p={1} pt={0} gap={0.5}>
              <CalculateSharp color="secondary" />
              <Typography variant="caption">{rule?.description}</Typography>
            </CenterX>
          </TriggerWithConfirmModal>
        )}
      </Box>
    </DrawerContentWrapper>
  );
};

export const EditRulePanel = ({ ruleId, createRuleMode, handleFormClose }: EditRuleProps) => {
  const { refetch, data, isLoading, isFetching, isError } = useGetNotificationRuleQuery(ruleId!, {
    skip: !ruleId || createRuleMode,
    refetchOnMountOrArgChange: true,
  });

  if (createRuleMode) {
    return (
      <Drawer open anchor="right" variant="persistent">
        <EditRule handleFormClose={handleFormClose} />
      </Drawer>
    );
  }

  if (isError) {
    return (
      <AlertWithRefetch onRetryClick={refetch}>Error getting notification detail</AlertWithRefetch>
    );
  }

  return (
    <Drawer open={ruleId !== null} anchor="right" variant="persistent">
      {isLoading || isFetching || !data ? (
        <DrawerContentWrapper flex={1}>
          <Spinner />
        </DrawerContentWrapper>
      ) : (
        <EditRule rule={data!} handleFormClose={handleFormClose} />
      )}
    </Drawer>
  );
};
