import { DefaultFilters } from '@appTypes/helpers/redux';
import { OrganizationSimpleDto } from '@appTypes/models/organization.dto';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { OrganizationsAction } from '../organization/actionCreators';
import { OrganizationActionsType } from '../organization/actionTypes';
import { OrganizationsNonFilteredAction } from './actionCreators';
import { OrganizationsNonFilteredActionsType } from './actionTypes';

const initialState = {
  organizationsNonFiltered: null as Nullable<WPage<OrganizationSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  filters: {
    page: 1,
    pageSize: 10,
  } as DefaultFilters,
};

export type OrganizationStateType = typeof initialState;

export const organizationsNonFilteredReducer = (
  state = initialState,
  action: OrganizationsNonFilteredAction | OrganizationsAction,
): OrganizationStateType => {
  const reducerHandler = createReducerHandler<OrganizationStateType, OrganizationSimpleDto>(
    'organizationsNonFiltered',
    state,
  );

  switch (action.type) {
    case OrganizationsNonFilteredActionsType.UPDATE_ORGANIZATIONS_NON_FILTERED_PAGES:
      return reducerHandler.updateFilter(action.payload);
    case OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED:
      return reducerHandler.request();
    case OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED_SUCCESS:
      return reducerHandler.requestSuccess(action.payload);
    case OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED_FAILED:
      return reducerHandler.requestFailed(action.payload);
    case OrganizationActionsType.CREATE_ORGANIZATION_SUCCESS:
      return initialState;
    case OrganizationActionsType.UPDATE_ORGANIZATION_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
