import './GlobalStyles.css';
import { experimental_extendTheme } from '@mui/material';
import { components } from 'styles/overrides';
import { palette } from './palette';

export const wattstorTheme = experimental_extendTheme({
  colorSchemes: {
    light: {
      palette,
    },
  },
  typography: {
    h1: {
      fontWeight: 400,
    },
    h2: {
      fontWeight: 400,
    },
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontSize: '16px',
    },
  },
  spacing: 8,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-sizeLarge.MuiButton-containedWarning': {
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              padding: '8px 22px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: 'var(--mui-palette-warning-dark)',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedWarning': {
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              padding: '6px 16px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: 'var(--mui-palette-warning-dark)',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedWarning': {
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              padding: '4px 10px',
              borderRadius: 'var(--mui-shape-borderRadius)',
              background: 'var(--mui-palette-warning-dark)',
              boxShadow: 'var(--mui-shadows-4)',
              opacity: '1',
            },
          },
        },
      },
    },
    ...components,
  },
});
