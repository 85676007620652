import { UserRole } from '@appTypes/models/user.dto';
import { Star, Person } from '@mui/icons-material';

const RoleIcon = ({ role }: { role?: UserRole }) => {
  if (!role) {
    return <Person color="secondary" />;
  }

  return [UserRole.SUPERADMIN, UserRole.ADMIN].includes(role) ? (
    <Star color="secondary" />
  ) : (
    <Person color="secondary" />
  );
};

export default RoleIcon;
