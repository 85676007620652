import { useEffect, useState } from 'react';
import { SiteIdName } from '@appTypes/models/site.dto';
import { useOnUpdate } from '@hooks';
import { Box, Typography } from '@mui/material';
import { getLastVisitedSitesService } from '@services/lastVisitedSites';
import { MAX_LAST_VISITED_SITES } from '@settings/config';
import { useLocation } from 'react-router';
import { AllSites } from './AllSites/AllSites';
import { SitesNavList } from './SitesNavList/SitesNavList';

export const DashboardsNavItem = () => {
  const [allSitesModalVisible, setAllSitesModalVisible] = useState(false);

  const { pathname } = useLocation();
  const lastVisitedSitesService = getLastVisitedSitesService(MAX_LAST_VISITED_SITES);

  const [lastVisitedSites, setLastVisitedSites] = useState<SiteIdName[]>(
    lastVisitedSitesService.getLastVisitedSites(),
  );

  useEffect(() => {
    const handleLastVisitedSiteChange = () => {
      setLastVisitedSites(lastVisitedSitesService.getLastVisitedSites());
    };

    window.addEventListener('last-sites-changed', handleLastVisitedSiteChange);
    return () => window.removeEventListener('last-sites-changed', handleLastVisitedSiteChange);
  }, [lastVisitedSitesService, lastVisitedSites]);

  useOnUpdate(() => {
    if (!allSitesModalVisible) {
      return;
    }

    setAllSitesModalVisible(false);
  }, [pathname]);

  return (
    <Box pl={5} pr={4}>
      {(() => {
        if (!lastVisitedSites || lastVisitedSites.length === 0) {
          return (
            <Typography ml={3}>There are no last visited sites for the current user.</Typography>
          );
        }

        return (
          <SitesNavList
            maxItemsVisible={MAX_LAST_VISITED_SITES}
            sites={lastVisitedSites}
            showSearchBox={false}
          />
        );
      })()}
      <Box ml={2} mt={2}>
        <AllSites />
      </Box>
    </Box>
  );
};
