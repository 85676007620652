import { ReactNode } from 'react';
import { divideWithSI } from '@components/Chart/utils/helpers';
import { Flex } from '@components/LayoutUtils';
import { usePalette } from '@hooks/usePalette';
import { Box } from '@mui/material';
import { TextLarge, TextNormal } from './components';

type CardSummaryItemProps = {
  title: ReactNode;
  value: number;
  unit?: string;
  valueColor?: string;
  enableSI?: boolean;
  decimals?: number;
};

const CardSummaryItem = ({
  value,
  title,
  unit,
  valueColor,
  decimals = 1,
  enableSI = true,
}: CardSummaryItemProps) => {
  const currentPalette = usePalette();

  if (!value) return null;

  let parsedValue = value.toFixed(decimals);
  const { resultValue, prefix } = divideWithSI(value, decimals);

  if (enableSI) {
    parsedValue = resultValue.toFixed(decimals);
  }

  return (
    <Box>
      <Flex alignItems="baseline">
        <TextLarge color={valueColor || currentPalette.primary.main}>{parsedValue}</TextLarge>
        {unit ? (
          <TextNormal color={currentPalette.text.secondary} smaller pl={1}>
            {prefix}
            {unit}
          </TextNormal>
        ) : null}
      </Flex>
      <TextNormal color={currentPalette.text.secondary}>{title}</TextNormal>
    </Box>
  );
};

export default CardSummaryItem;
