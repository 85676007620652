import { PathParam } from '@appTypes/common';
import { idParam } from '@utils/utils';

export const paths = {
  login: '/login',
  forgotPassword: '/forgot-password',
  verifyCode: '/verify-code',
  adminTools: '/admin-tools',
  sites: '/sites',
  siteOverview: (id: PathParam) => `/sites/${idParam(id)}/overview`,
  siteDataAnalysis: (id: PathParam) => `/sites/${idParam(id)}/data-analysis`,
  alarms: '/alarms',
  notifications: '/notifications',
  notificationRules: '/notifications/rules',
  configuration: '/configuration',
  configurationUserNew: '/configuration/users/new',
  configurationUser: (id: PathParam) => `/configuration/users/${idParam(id)}`,
  configurationEcd: (id: PathParam) => `/configuration/ecds/${idParam(id)}`,
  configurationOrganizationNew: '/configuration/organizations/new',
  configurationOrganization: (id: PathParam) => `/configuration/organizations/${idParam(id)}`,
  configurationSiteNew: '/configuration/sites/new',
  configurationSite: (id: PathParam) => `/configuration/sites/${idParam(id)}`,
};
export type Path = string | ((id: PathParam) => string);
export type PathsType = typeof paths;
