import { useGetNotificationsQuery } from '@apiRtk/notifications';
import { FilterValues } from '@appTypes/helpers/filters';
import { Spinner } from '@components/Spinner';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useRedirect, useSearchParamsAsState } from '@hooks';
import { SearchParamsStateType } from '@hooks/useSearchParamsAsState';
import { Settings } from '@mui/icons-material';
import NotificationIcon from '@mui/icons-material/Notifications';
import { Alert, Button, Grid } from '@mui/material';
import { NotificationsList } from './NotificationsList';
import { Toolbar } from './Toolbar';

export const Notifications = () => {
  const filtersDefaults: SearchParamsStateType = {
    descending: { type: 'string', default: 'true' },
    order_by: { type: 'string', default: 'start' },
    page: { type: 'number', default: 1 },
    page_size: { type: 'number', default: 5 },
    site_id: { type: 'number', default: null },
    search_string: { type: 'string', default: '' },
    state: { type: 'string', default: 'active' },
  };

  const [filterParams, setFilterParams, removeFilterParam] =
    useSearchParamsAsState(filtersDefaults);

  const { data, isError, isLoading, isFetching } = useGetNotificationsQuery(filterParams, {
    refetchOnMountOrArgChange: true,
  });

  const setFilter = (filterName: string, filterValue: FilterValues) => {
    // Note: All non-page filters resets page
    const pageReset = filterName !== 'page' ? { page: 1 } : {};
    if (filterValue === null || typeof filterValue === 'undefined') {
      removeFilterParam(filterName);
    } else {
      setFilterParams({ [filterName]: filterValue, ...pageReset });
    }
  };

  const redirect = useRedirect();

  let content = (
    <NotificationsList
      itemsCount={Number(data?.count)}
      items={data?.notifications || []}
      loading={false}
      filters={filterParams}
      setFilters={setFilter}
    />
  );

  if (isError || !data) {
    content = (
      <Alert variant="filled" color="warning">
        No data available. Please try again later.
      </Alert>
    );
  }

  if (isLoading || isFetching) {
    content = <Spinner />;
  }

  return (
    <Grid>
      <TitleBar title="Notifications" pb={2} IconComponent={NotificationIcon} />
      <Button
        variant="outlined"
        color="primary"
        endIcon={<Settings />}
        onClick={() => {
          redirect((paths) => paths.notificationRules);
        }}
      >
        Setup rules
      </Button>
      <>
        <Toolbar filters={filterParams} setFilters={setFilter} />
        {content}
      </>
    </Grid>
  );
};

export default Notifications;
