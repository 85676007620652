import { useEffect } from 'react';
import { useGetSiteQuery } from '@apiRtk/sites';
import { CenterY, GapY } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useRedirect } from '@hooks';
import { Widgets } from '@mui/icons-material';
import LineChartIcon from '@mui/icons-material/QueryStats';
import { Box, Alert, Typography, Button } from '@mui/material';
import { getLastVisitedSitesService } from '@services/lastVisitedSites';
import { MAX_LAST_VISITED_SITES } from '@settings/config';
import { useParams } from 'react-router-dom';
import DataExport from './DataExport/DataExport';
import EnergyFlow from './EnergyFlow';
import PerformanceOverTime from './PerformanceOverTime/PerformanceOverTime';

const Overview = () => {
  const params = useParams();
  const siteId = parseInt(params.id as string, 10);
  const redirect = useRedirect();
  const lastVisitedSites = getLastVisitedSitesService(MAX_LAST_VISITED_SITES);

  const {
    data: siteData,
    isLoading: siteLoading,
    isFetching: siteFetching,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  useEffect(() => {
    if (siteData?.name) {
      lastVisitedSites.addSite({
        id: siteData.id,
        name: siteData.name,
      });
    }
  }, [siteData, lastVisitedSites]);

  const firstSiteEcd = siteData?.imps[0]?.id;

  const title = siteData?.name ? `${siteData.name} - Site overview` : 'Site overview';

  if (siteError || !siteId) {
    return (
      <>
        <TitleBar title="Site overview" IconComponent={Widgets} />
        <Box p={5}>
          <Alert variant="filled" color="warning">
            No data available. Please try again later.
          </Alert>
        </Box>
      </>
    );
  }

  if (siteLoading || siteFetching) {
    return <Spinner />;
  }

  if (!firstSiteEcd) {
    return (
      <>
        <TitleBar title="Site overview" IconComponent={Widgets} />
        <Box p={5}>
          <Alert variant="filled" color="warning">
            No ECD found for this site. Please contact support.
          </Alert>
        </Box>
      </>
    );
  }

  return (
    <>
      <TitleBar title={title} IconComponent={Widgets} />

      <Typography variant="h5" pt={4} pb={2}>
        System status
      </Typography>

      <EnergyFlow ecdId={firstSiteEcd} />

      <PerformanceOverTime siteId={siteId} />

      <GapY size={2} />

      <DataExport />

      <CenterY pt={2}>
        <Button
          startIcon={<LineChartIcon />}
          type="button"
          onClick={() => {
            redirect((paths) => paths.siteDataAnalysis(siteId));
          }}
        >
          Go to data analysis
        </Button>
      </CenterY>
    </>
  );
};

export default Overview;
