import { BackButton } from '@components/Buttons/BackButton';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { paths } from 'paths';
import CreateSiteForm from './CreateSiteForm';

const CreateSite = () => (
  <>
    <BackButton path={paths.configuration}>Back to configuration list</BackButton>
    <TitleBar my={4} title="Create new site" titleVariant="h5" />
    <CreateSiteForm />
  </>
);

export default CreateSite;
