import { ActionCreator } from '@appTypes/helpers/redux';
import {
  UsersNonFilteredActionsType,
  FetchedUsersNonFiltered,
  RequestUsersNonFilteredFailed,
  RequestUsersNonFiltered,
  RequestUsersNonFilteredSuccess,
  UpdateUsersNonFilteredPages,
} from './actionTypes';

export const requestUsersNonFiltered: ActionCreator<RequestUsersNonFiltered> = () => ({
  type: UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED,
});

export const requestUsersNonFilteredSuccess: ActionCreator<RequestUsersNonFilteredSuccess> = (
  payload,
) => ({
  type: UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED_SUCCESS,
  payload,
});

export const requestUsersNonFilteredFailed: ActionCreator<RequestUsersNonFilteredFailed> = (
  payload,
) => ({
  type: UsersNonFilteredActionsType.REQUEST_USERS_NON_FILTERED_FAILED,
  payload,
});

export const fetchedUsersNonFiltered: ActionCreator<FetchedUsersNonFiltered> = ({
  page = 1,
  pageSize = 5,
}) => ({
  type: UsersNonFilteredActionsType.FETCHED_USERS_NON_FILTERED,
  payload: { page, pageSize, search: '' },
});

export type UsersNonFilteredAction =
  | RequestUsersNonFiltered
  | RequestUsersNonFilteredSuccess
  | RequestUsersNonFilteredFailed
  | FetchedUsersNonFiltered
  | UpdateUsersNonFilteredPages;
