export type AppEnvironment = 'production' | 'development';

export enum FEATURE_FLAGS {
  DASHBOARD_NEW_ENERGY_FLOW = 'newEnergyFlow',
  DISABLE_COMING_SOON_OVERLAYS = 'comingSoonOverlays',
  DROP_CALCULATOR = 'dropCalculator',
}

export type FeatureFlagConfig = {
  name: FEATURE_FLAGS;
  label: string;
  environments: AppEnvironment[];
};

export type PathParam = number | Nullable<string>;
