import React, { useRef, useEffect, useCallback } from 'react';
import ApexCharts from 'apexcharts';
import { usePreviousValue } from '../hooks/usePreviousValue';

const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item) && item !== null;

const extend = (target, source) => {
  if (!isObject(target) || !isObject(source)) return { ...target, ...source };

  return {
    ...target,
    ...Object.keys(source).reduce((acc, key) => {
      acc[key] = isObject(target[key]) ? extend(target[key], source[key]) : source[key];
      return acc;
    }, {}),
  };
};

const CustomReactApexcharts = (props) => {
  const [ready, setReady] = React.useState(false);
  const chartRef = useRef(null);
  const prevProps = usePreviousValue(props);
  const apexChartsRef = useRef(null);

  const { onReadyChange } = props;

  const getConfig = useCallback(() => {
    const { type, height, width, series, options } = props;
    const newOptions = {
      chart: {
        type,
        height,
        width,
      },
      series,
    };

    const resultOptions = extend(options, newOptions);

    return { ...resultOptions };
  }, [props]);

  useEffect(() => {
    const { current } = chartRef;

    apexChartsRef.current = new ApexCharts(current, getConfig());

    const renderPromise = apexChartsRef.current.render();

    renderPromise.then(() => {
      setReady(true);
    });

    return () => {
      setReady(false);

      if (apexChartsRef.current && typeof apexChartsRef.current.destroy === 'function') {
        apexChartsRef.current.destroy();
      }
    };
  }, [getConfig]);

  useEffect(() => {
    if (
      !ready ||
      typeof ApexCharts?.exec !== 'function' ||
      typeof props?.options?.chart?.id === 'undefined'
    )
      return;

    const { options, series, height, width } = props || {};
    const prevOptions = JSON.stringify(prevProps?.options);
    const prevSeries = JSON.stringify(prevProps?.series);
    const currentOptions = JSON.stringify(options);
    const currentSeries = JSON.stringify(series);

    if (
      prevOptions !== currentOptions ||
      prevSeries !== currentSeries ||
      height !== prevProps.height ||
      width !== prevProps.width
    ) {
      let updatePromise;
      onReadyChange?.(false);
      if (prevSeries === currentSeries) {
        // series has not changed, but options or size have changed
        updatePromise = ApexCharts.exec(options.chart.id, 'updateOptions', getConfig()); // exec method has to be used instead updateOptions, which incorrectly clones options for all grouped charts
      } else if (
        prevOptions === currentOptions &&
        height === prevProps.height &&
        width === prevProps.width
      ) {
        // options or size have not changed, just the series has changed
        updatePromise = ApexCharts.exec(options.chart.id, 'updateSeries', series);
      } else {
        // both might be changed
        updatePromise = ApexCharts.exec(options.chart.id, 'updateOptions', getConfig()); // exec method has to be used instead updateOptions, which incorrectly clones options for all grouped charts
      }

      updatePromise?.then(() => {
        if (series?.length > 0) {
          onReadyChange?.(true);
        }
      });
    }
  });

  return <div ref={chartRef} />;
};

export default React.memo(CustomReactApexcharts);
