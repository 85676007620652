import { sitesAPI } from '@api/sites';
import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { fork } from 'redux-saga/effects';
import {
  requestSitesNonFiltered,
  requestSitesNonFilteredSuccess,
  requestSitesNonFilteredFailed,
} from './actionCreators';
import { SitesNonFilteredActionsType } from './actionTypes';
import { SitesNonFilteredSelectorReturn, getSitesNonFilteredSelector } from './selectors';

export function* sitesNonFilteredWatcher() {
  yield fork<
    CreateSagaCrudWatcher<SitesNonFilteredSelectorReturn, SiteSimpleDto, unknown, unknown>
  >(createSagaCRUDWatcher, {
    selector: getSitesNonFilteredSelector,
    getList: {
      actionName: SitesNonFilteredActionsType.FETCHED_SITES_NON_FILTERED,
      updateFilterActionName: SitesNonFilteredActionsType.UPDATE_SITES_NON_FILTERED_PAGES,
      apiCall: sitesAPI.getSites,
      request: requestSitesNonFiltered,
      requestSuccess: requestSitesNonFilteredSuccess,
      requestFailed: requestSitesNonFilteredFailed,
    },
  });
}
