import { CircularProgress, CircularProgressProps, GridProps, styled } from '@mui/material';
import { palette } from 'styles/palette';
import { CenterXY } from './LayoutUtils';

export type SpinnerProps = {
  size?: CircularProgressProps['size'];
  wrapperProps?: GridProps;
  color?: string;
};

export const Spinner = ({ size = 40, color, wrapperProps }: SpinnerProps) => (
  <CenterXY {...wrapperProps}>
    <CircularProgress size={size} data-testid="spinner" sx={{ color: color || palette.primary }} />
  </CenterXY>
);

const Body = styled('div')`
  width: 60px;
  height: 50px;
  --m: no-repeat linear-gradient(90deg, #000 70%, #0000 0);
  mask:
    var(--m) calc(0 * 100% / 4) 100% / calc(100% / 5) calc(1 * 100% / 5),
    var(--m) calc(1 * 100% / 4) 100% / calc(100% / 5) calc(2 * 100% / 5),
    var(--m) calc(5 * 100% / 4) 100% / calc(100% / 5) calc(3 * 100% / 5),
    var(--m) calc(3 * 100% / 4) 100% / calc(100% / 5) calc(4 * 100% / 5),
    var(--m) calc(2 * 100% / 4) 100% / calc(100% / 5) calc(5 * 100% / 5);
  background: ${`linear-gradient(${palette.secondary.main} 0 0) left/0% 100% no-repeat ${palette.white}`};

  animation: l14 1s infinite steps(6);

  position: relative;
  top: calc(50% - 25px);
  left: calc(50% - 30px);

  @keyframes l14 {
    100% {
      background-size: 120% 100%;
    }
  }
`;

const Wrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  z-index: 1000;
`;

export const GraphLoader = () => (
  <Wrapper>
    <Body />
  </Wrapper>
);
