import { useState } from 'react';
import { BoxModal } from '@components/Modals/BoxModal';
import { useOnUpdate } from '@hooks';
import Add from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { palette } from 'styles/palette';
import { SitesNavList } from '../SitesNavList/SitesNavList';

export const AllSites = () => {
  const [allSitesModalVisible, setAllSitesModalVisible] = useState(false);

  const { pathname } = useLocation();

  useOnUpdate(() => {
    if (!allSitesModalVisible) {
      return;
    }

    setAllSitesModalVisible(false);
  }, [pathname]);

  const { currentUser } = useSelector(selectAuthSlice);

  if (!currentUser) return null;

  const { sites } = currentUser;

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<Add />}
        onClick={() => setAllSitesModalVisible(true)}
      >
        All sites
      </Button>
      <BoxModal
        open={allSitesModalVisible}
        onClose={() => setAllSitesModalVisible(false)}
        boxProps={{
          sx: {
            maxWidth: 400,
            paddingY: 2,
            paddingX: 0,
            bgcolor: palette.neutral.light,
          },
        }}
      >
        <Box sx={{ height: 400, overflowY: 'scroll' }} className="appScrollBar">
          <SitesNavList
            sites={sites}
            refetchLastSites
            setAllSitesModalVisible={setAllSitesModalVisible}
          />
        </Box>
      </BoxModal>
    </>
  );
};
