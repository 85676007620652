import { useState } from 'react';
import { BackButton } from '@components/Buttons/BackButton';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { Flex } from '@components/LayoutUtils';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useDataByPreviousPath } from '@hooks';
import { FlashOn } from '@mui/icons-material';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { useEcdConfigurationContext } from '@providers/EcdConfigurationProvider';
import { paths } from 'paths';

export const EcdConfigurationHeader = ({
  title,
  onCancelConfirm,
}: {
  title: string | undefined;
  onCancelConfirm(): void;
}) => {
  const [showCanceledDialog, setShowCanceledDialog] = useState(false);
  const { allChangesCount, isFormFreezed } = useEcdConfigurationContext();
  const showCancelBtn = allChangesCount > 0;

  const backBtnTxt = useDataByPreviousPath<string>(({ isPreviousPath, noPreviousPath }) => {
    if (showCancelBtn) {
      return 'Cancel changes';
    }

    if (isPreviousPath(paths.configurationSite)) {
      return 'Back to site';
    }

    if (isPreviousPath(paths.configuration) || noPreviousPath) {
      return 'Back to configuration list';
    }

    return 'Back';
  });

  return (
    <>
      <Dialog open={showCanceledDialog}>
        <Box p={4}>
          <Typography variant="subtitle1" fontWeight={700}>
            All your changes will be canceled, are you sure?
          </Typography>
          <Flex mt={2} justifyContent="end" gap={2}>
            <Button
              variant="secondaryFade"
              data-testid="ecd-cancel-changes-confirm"
              onClick={() => {
                setShowCanceledDialog(false);
                onCancelConfirm();
              }}
            >
              Yes, cancel my changes
            </Button>
            <Button
              color="primary"
              data-testid="ecd-cancel-changes-reject"
              variant="outlined"
              onClick={() => setShowCanceledDialog(false)}
            >
              No, go back
            </Button>
          </Flex>
        </Box>
      </Dialog>
      <BackButton
        sx={{ ml: 1 }}
        disabled={isFormFreezed}
        path={paths.configuration}
        query={{ selectedItem: ConfiguredItem.ECDS }}
        onClick={showCancelBtn ? () => setShowCanceledDialog(true) : undefined}
      >
        {backBtnTxt}
      </BackButton>
      <TitleBar my={4} title={`${title || 'ECD'} - Configuration`} IconComponent={FlashOn} />
    </>
  );
};
