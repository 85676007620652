import { PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import CardHead from '@components/Cards/CardHead';
import CardListItem from '@components/Cards/CardListItem';
import { CardContentWithLoader } from '@components/Cards/CardWithLoader';
import { CardContainer, TextLarge, TextNormal } from '@components/Cards/components';
import { Flex } from '@components/LayoutUtils';
import Overlay from '@components/Overlay/Overlay';
import { usePalette } from '@hooks/usePalette';
import { ReactComponent as IconCurrency } from '@icons/currency.svg';
import { Box, Grid } from '@mui/material';
import { formatCurrency } from '@utils/utils';
import BarGraph from '../BarGraph';
import { CardTooltip } from './CardTooltip';
import ComingSoonBig from './ComingSoonOverlay';

const keyToTitleMap: Record<string, string> = {
  'balance-with-wattstor': 'Balance with Wattstor',
  'balance-without-wattstor': 'Balance without Wattstor',
};

interface CardReturnOnInvestmentProps {
  isFeatureEnabled: boolean;
  data?: PerformanceOverTimeDto['returnOnInvestment'];
  loading?: boolean;
}

const CardReturnOnInvestment = ({
  isFeatureEnabled,
  data,
  loading,
}: CardReturnOnInvestmentProps) => {
  const currentPalette = usePalette();

  const chartColors = [currentPalette.barGraph.main, currentPalette.barGraph.light];

  const series = data?.items
    .map((item) => ({
      name: item.key,
      data: [item.value],
    }))
    .concat({ name: 'empty-series', data: [0] });

  return (
    <CardContainer>
      <CardHead
        title="Return on investment"
        subtitle="Energy import and export balance"
        icon={<IconCurrency />}
        rightSlot={<CardTooltip textKey="returnOnInvestment" />}
      />
      <CardContentWithLoader loading={loading} position="relative">
        {data ? (
          <>
            {!isFeatureEnabled ? (
              <Overlay>
                <ComingSoonBig featureName="Return on investment" />
              </Overlay>
            ) : null}
            <BarGraph colors={chartColors} series={series || []} />
            <Grid item xs={12} pb={3}>
              <Box>
                <Flex alignItems="baseline" gap="4px">
                  <TextNormal color={currentPalette.text.secondary} smaller>
                    {data.unit}
                  </TextNormal>
                  <TextLarge
                    color={data.total < 0 ? currentPalette.error.main : currentPalette.success.dark}
                  >
                    {formatCurrency(data.total)}
                  </TextLarge>
                </Flex>
                <TextNormal color={currentPalette.text.secondary}>Return on investment</TextNormal>
              </Box>
            </Grid>
            {data.items.map(({ key, value }, index) => {
              const icon =
                key === 'balance-with-wattstor' ? (
                  <img src="/src/static/images/logo.png" height={10} alt="Wattstor" />
                ) : null;

              return (
                <CardListItem
                  key={key}
                  color={chartColors[index]}
                  value={value || 0}
                  prefix={data.unit}
                  suffix={keyToTitleMap[key] || key}
                  icon={icon}
                  currency
                  light
                  boldValue
                />
              );
            })}
          </>
        ) : (
          <Box sx={{ height: '100px' }} />
        )}
      </CardContentWithLoader>
    </CardContainer>
  );
};

export default CardReturnOnInvestment;
