import { RelationsGrid, RelationsGridProps } from '@components/RelationsForm/RelationsGrid';
import { getOrganizationsSelector } from '@redux/ducks/organization/selectors';
import { useSelector } from 'react-redux';

export type OrganizationRelationsGridProps<TItem extends {} = {}> = {
  itemsField: 'users' | 'sites';
} & Omit<RelationsGridProps<TItem>, 'items'>;

export function OrganizationRelationsGrid<TItem extends {}>({
  itemsField,
  ...props
}: OrganizationRelationsGridProps<TItem>) {
  const {
    current: { data },
  } = useSelector(getOrganizationsSelector);

  const items = data ? data[itemsField] : [];

  return <RelationsGrid items={items} {...props} />;
}
