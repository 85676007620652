import { usersAPI } from '@api/users';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import { createSagaCRUDWatcher } from '@redux/common/createSagaCRUDWatcher/createSagaCRUDWatcher';
import { CreateSagaCrudWatcher } from '@redux/common/createSagaCRUDWatcher/types';
import { fork } from 'redux-saga/effects';
import {
  requestUsersNonFiltered,
  requestUsersNonFilteredSuccess,
  requestUsersNonFilteredFailed,
} from './actionCreators';
import { UsersNonFilteredActionsType } from './actionTypes';
import { UsersNonFilteredSelectorReturn, getUsersNonFilteredSelector } from './selectors';

export function* usersNonFilteredWatcher() {
  yield fork<
    CreateSagaCrudWatcher<UsersNonFilteredSelectorReturn, UserSimpleDto, unknown, unknown>
  >(createSagaCRUDWatcher, {
    selector: getUsersNonFilteredSelector,
    getList: {
      actionName: UsersNonFilteredActionsType.FETCHED_USERS_NON_FILTERED,
      updateFilterActionName: UsersNonFilteredActionsType.UPDATE_USERS_NON_FILTERED_PAGES,
      apiCall: usersAPI.getUsers,
      request: requestUsersNonFiltered,
      requestSuccess: requestUsersNonFilteredSuccess,
      requestFailed: requestUsersNonFilteredFailed,
    },
  });
}
