import { all, ForkEffect } from 'redux-saga/effects';
import { sagaApiErrorHandler } from './common/sagaApiErrorHandler';
import { alertsWatcher } from './ducks/alerts/sagas';
import { ecdsWatcher } from './ducks/ecds/sagas';
import { organizationsWatcher } from './ducks/organization/sagas';
import { organizationsNonFilteredWatcher } from './ducks/organizationsNonFiltered/sagas';
import { sitesWatcher } from './ducks/sites/sagas';
import { sitesNonFilteredWatcher } from './ducks/sitesNonFiltered/sagas';
import { usersWatcher } from './ducks/users/sagas';
import { usersNonFilteredWatcher } from './ducks/usersNonFiltered/sagas';

function* keepAlive(sagaFn: () => Generator<ForkEffect<void>, void, unknown>): unknown {
  try {
    yield all([sagaFn()]);
  } catch (error) {
    yield sagaApiErrorHandler(error);

    yield keepAlive(sagaFn);
  }
}

export default function* rootSaga() {
  const watchers = [
    sitesWatcher,
    organizationsWatcher,
    organizationsNonFilteredWatcher,
    usersWatcher,
    usersNonFilteredWatcher,
    ecdsWatcher,
    alertsWatcher,
    sitesNonFilteredWatcher,
  ].map((watcher) => keepAlive(watcher));

  yield all(watchers);
}
