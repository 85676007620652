import { ApiError } from '@appTypes/api/error';
import {
  ActionType,
  RequestSuccessActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import { OrganizationSimpleDto } from '@appTypes/models/organization.dto';
import { OrganizationFilters } from '@appTypes/organization/filters';

export enum OrganizationsNonFilteredActionsType {
  REQUEST_ORGANIZATIONS_NON_FILTERED = 'REQUEST_ORGANIZATIONS_NON_FILTERED',
  REQUEST_ORGANIZATIONS_NON_FILTERED_SUCCESS = 'REQUEST_ORGANIZATIONS_NON_FILTERED_SUCCESS',
  REQUEST_ORGANIZATIONS_NON_FILTERED_FAILED = 'REQUEST_ORGANIZATIONS_NON_FILTERED_FAILED',
  FETCHED_ORGANIZATIONS_NON_FILTERED = 'FETCHED_ORGANIZATIONS_NON_FILTERED',
  UPDATE_ORGANIZATIONS_NON_FILTERED_PAGES = 'UPDATE_ORGANIZATIONS_NON_FILTERED_PAGES',
}

export type RequestOrganizationsNonFiltered =
  ActionType<OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED>;

export type RequestOrganizationsNonFilteredSuccess = RequestSuccessActionType<
  OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED_SUCCESS,
  OrganizationSimpleDto
>;

export type FetchedOrganizationsNonFiltered = ActionType<
  OrganizationsNonFilteredActionsType.FETCHED_ORGANIZATIONS_NON_FILTERED,
  OrganizationFilters
>;

export type RequestOrganizationsNonFilteredFailed = ActionType<
  OrganizationsNonFilteredActionsType.REQUEST_ORGANIZATIONS_NON_FILTERED_FAILED,
  ApiError
>;

export type UpdateOrganizationsNonFilteredPages = UpdateFilterActionType<
  OrganizationsNonFilteredActionsType.UPDATE_ORGANIZATIONS_NON_FILTERED_PAGES,
  OrganizationFilters
>;
