import { useMemo } from 'react';
import { FilterValues } from '@appTypes/helpers/filters';
import { NotificationRuleDto } from '@appTypes/models/notificationRule.dto';
import { NotificationRuleGridCellProps } from '@appTypes/notificationRules/cells';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { NotificationCell } from '@components/DataGridCells/NotificationCell';
import { SiteCell } from '@components/DataGridCells/SiteCell';
import { XDataGrid } from '@components/XDataGrid/XDataGrid';
import { RenderDeviceCell } from './cells/RenderDeviceCell';

type NotificationRuleListProps = {
  items: NotificationRuleDto[];
  itemsCount: number;
  loading: boolean;
  filters: Record<string, FilterValues>;
  setFilters: (filterName: string, filterValue: FilterValues) => void;
  setRuleIdToEdit(rule: number): void;
};

export const NotificationRuleList = ({
  setRuleIdToEdit,
  items,
  itemsCount,
  loading,
  filters,
  setFilters,
}: NotificationRuleListProps) => {
  const dataGridColumns = useMemo(
    () =>
      [
        {
          field: 'device',
          headerName: 'Device',
          flex: 0.25,
          renderCell: RenderDeviceCell,
        },
        {
          field: 'site_name',
          headerName: 'Site',
          flex: 0.2,
          renderCell: SiteCell,
        },
        {
          field: 'description',
          headerName: 'Notification',
          minWidth: 250,
          renderCell: (props: NotificationRuleGridCellProps) => (
            <DetailCell onDetailClick={() => setRuleIdToEdit(props.row.id)} detailBtnText="Edit">
              <NotificationCell {...props} />
            </DetailCell>
          ),
        },
      ].map((column) => ({
        sortable: false,
        minWidth: 100,
        flex: 1,
        ...column,
      })),
    [setRuleIdToEdit],
  );

  return (
    <XDataGrid<NotificationRuleDto>
      items={items}
      columns={dataGridColumns}
      page={filters.page as number}
      onPaginationChange={(page) => setFilters('page', page)}
      loading={loading}
      itemsCount={itemsCount}
      wrapperSx={{ width: '80%' }}
    />
  );
};
