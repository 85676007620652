import { getMinMaxFromArray } from '@utils/utils';
import { ApexOptions } from 'apexcharts';
import { palette, ColorShades, WattstorColors, wattstorColors } from 'styles/palette';

export const generateCardGraphColors = (
  colorShade: ColorShades,
  reverse: boolean = false,
  limit?: number,
) => {
  const graphColors: string[] = [];
  for (let i = 100; i <= 900; i += 100) {
    const colorKey = `${colorShade}${i === 100 ? '50' : i}`;
    if (wattstorColors[colorKey as WattstorColors]) {
      graphColors.push(wattstorColors[colorKey as WattstorColors]);
    }
    if (limit && graphColors.length >= limit) {
      break;
    }
  }
  return reverse ? graphColors.reverse() : graphColors;
};

export const createPointAnnotation = (xIndex: number, y: number, color: string) => ({
  x: xIndex + 1,
  y,
  marker: {
    size: 6,
    fillColor: color,
    strokeColor: 'white',
  },
});

export const createMinMaxMarkers = (datapoints: number[]) => {
  const { min, max } = getMinMaxFromArray(datapoints);

  return [
    createPointAnnotation(
      datapoints.findIndex((val) => val === min),
      min,
      palette.graphPointMin,
    ),
    createPointAnnotation(
      datapoints.findIndex((val) => val === max),
      max,
      palette.graphPointMax,
    ),
  ];
};

export const optionsGaugeChart: ApexOptions = {
  chart: {
    animations: {
      enabled: false,
    },
  },
  colors: [...generateCardGraphColors('skyBlue')],
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      filter: {
        type: 'none',
      },
    },
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      startAngle: -90,
      endAngle: 90,
      offsetY: 0,
      donut: {
        size: '75%',
        labels: {
          show: false,
        },
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    padding: {
      bottom: -100,
    },
  },
};

export const optionsAreaChart: ApexOptions = {
  grid: {
    padding: {
      top: 5,
    },
  },
  chart: {
    animations: {
      enabled: false,
    },
    stacked: true,
    sparkline: {
      enabled: true,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 0,
  },
  fill: {
    type: 'solid',
  },
  yaxis: {
    show: false,
    min: 0,
  },
  tooltip: {
    enabled: false /* Note: Disabled for now, will be used later */,
    fixed: {
      enabled: true,
      position: 'bottomLeft',
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: () => '',
      },
    },
    marker: {
      show: false,
    },
  },
};
