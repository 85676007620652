import { usePalette } from '@hooks/usePalette';

type LoaderProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  thickness?: number;
};

const Loader = ({ size = 32, thickness = 4, ...props }: LoaderProps) => {
  const currentPalette = usePalette();
  const crestSize = size - thickness;
  const diameter = size / 2 - thickness / 2;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`${-thickness} ${-thickness} ${size + thickness} ${size + thickness}`}
      stroke={currentPalette.common.white}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g strokeWidth={thickness}>
          <circle
            strokeOpacity="1"
            cx={diameter}
            cy={diameter}
            r={diameter}
            stroke={currentPalette.common.white}
          />
          <path
            d={`M${crestSize} ${crestSize / 2}c0-9.94-8.06-${crestSize / 2}-${crestSize / 2}-${
              crestSize / 2
            }`}
            stroke={currentPalette.success.main}
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from={`0 ${diameter} ${diameter}`}
              to={`360 ${diameter} ${diameter}`}
              dur="600ms"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  );
};

export default Loader;
