import { EcdFilters } from '@appTypes/ecds/filters';
import { EcdSimpleDto, EcdDto, EcdWithErrorDto } from '@appTypes/models/ecd.dto';
import { createReducerHandler } from '@redux/common/createReducerHandler';
import { defaultRequestState } from '@redux/common/defaultRequestState';
import { prepareError } from '@utils/data/prepareError';
import { EcdsAction } from './actionCreators';
import { EcdsActionsTypes } from './actionTypes';

const initialState = {
  ecds: null as Nullable<WPage<EcdSimpleDto>[]>,
  ...defaultRequestState,
  fetchedPages: [] as number[],
  itemsCount: 0,
  current: {
    data: null as Nullable<EcdWithErrorDto>,
    ...defaultRequestState,
  },
  update: defaultRequestState,
  filters: {
    page: 1,
    pageSize: 5,
    search: '',
    descending: false,
    orderBy: 'id',
  } as EcdFilters,
};

export const ecdsReducer = (state = initialState, action: EcdsAction): EcdsStateType => {
  const reducerHandler = createReducerHandler<EcdsStateType, EcdDto, EcdSimpleDto>('ecds', state);

  switch (action.type) {
    case EcdsActionsTypes.GET_ECD_SUCCESS:
      return reducerHandler.getCurrent(action.payload);
    case EcdsActionsTypes.SET_ECD_REQUEST_LOADING:
      return reducerHandler.setRequestLoading(action.payload);
    case EcdsActionsTypes.SET_ECD_REQUEST_ERROR:
      return reducerHandler.setRequestError(action.payload);
    case EcdsActionsTypes.CLEAR_ECD_CURRENT:
      return reducerHandler.clearCurrent();
    case EcdsActionsTypes.UPDATE_ECD_SUCCESS:
      return reducerHandler.updateSuccess(action.payload);
    case EcdsActionsTypes.REQUEST_ECDS:
      return reducerHandler.request();
    case EcdsActionsTypes.REQUEST_ECDS_SUCCESS: {
      const payload = {
        ...action.payload,
        items: action.payload.items.map((item) => ({
          ...item,
          connect: { ...defaultRequestState, loaded: false, alive: false },
        })),
      };

      return reducerHandler.requestSuccess(payload);
    }
    case EcdsActionsTypes.REQUEST_ECD_STATUS: {
      return {
        ...state,
        ecds:
          state.ecds?.map((ecd) =>
            ecd.id === action.payload
              ? {
                  ...ecd,
                  connect: { ...ecd.connect, loading: true },
                }
              : ecd,
          ) || null,
      };
    }
    case EcdsActionsTypes.REQUEST_ECD_STATUS_SUCCESS: {
      return {
        ...state,
        ecds:
          state.ecds?.map((ecd) =>
            ecd.id === action.payload.id
              ? {
                  ...ecd,
                  connect: {
                    ...defaultRequestState,
                    loaded: true,
                    alive: action.payload.alive,
                  },
                }
              : ecd,
          ) || null,
      };
    }
    case EcdsActionsTypes.REQUEST_ECD_STATUS_FAILED: {
      return {
        ...state,
        ecds:
          state.ecds?.map((ecd) =>
            ecd.id === action.payload.id
              ? {
                  ...ecd,
                  connect: {
                    ...defaultRequestState,
                    loaded: true,
                    alive: false,
                    error: prepareError(action.payload.error),
                  },
                }
              : ecd,
          ) || null,
      };
    }
    case EcdsActionsTypes.REQUEST_ECDS_FAILED:
      return reducerHandler.requestFailed(action.payload);
    case EcdsActionsTypes.UPDATE_ECDS_FILTER:
      return reducerHandler.updateFilter(action.payload);
    default:
      return state;
  }
};

export type EcdsStateType = typeof initialState;
