import { useState } from 'react';
import { StateFilter } from '@appTypes/helpers/filters';
import { FilterParamsTanstackDto } from '@appTypes/models/common.dto';
import { SortingState } from '@tanstack/react-table';

interface UseFiltersTanStackProps {
  pageSize: number;
  sortingState: SortingState;
}

export const useFiltersTanStack = ({ pageSize, sortingState }: UseFiltersTanStackProps) => {
  const [sorting, setSorting] = useState<SortingState>(sortingState);

  const [searchString, setSearchString] = useState('');

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize,
  });

  const [siteId, setSiteId] = useState<number | null>(null);
  const [stateFilter, setStateFilter] = useState<StateFilter | null>(null);

  const getFilters = (): FilterParamsTanstackDto => {
    const filters: FilterParamsTanstackDto = {
      page: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
      search_string: searchString,
    };

    if (sorting.length > 0) {
      const sort = sorting[0];
      filters.order_by = [sort.id];
      filters.descending = sort.desc;
    }

    if (siteId) {
      filters.site_id = siteId;
    }

    if (stateFilter) {
      filters.state = stateFilter;
    }

    return filters;
  };

  return {
    getFilters,
    sorting,
    searchString,
    pagination,
    siteId,
    stateFilter,
    setSorting,
    setSearchString,
    setPagination,
    setSiteId,
    setStateFilter,
  };
};
