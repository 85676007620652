import { useUpdateNotificationRuleMutation } from '@apiRtk/notificationRules';
import { NotificationRuleGridCellProps } from '@appTypes/notificationRules/cells';
import { Switch } from '@components/Switch';
import { useMutationAlerts } from '@hooks';
import { Box } from '@mui/material';
import _ from 'lodash';

export const RenderDeviceCell = ({ formattedValue, row }: NotificationRuleGridCellProps) => {
  const { site, is_active } = row;

  const [updateNotificationRule, updateResult] = useUpdateNotificationRuleMutation();

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateNotificationRule({
      site_id: site.id,
      is_active: checked,
      ..._.omit(row, ['site', 'user', 'is_active']),
    });
  };

  useMutationAlerts({
    mutationResult: updateResult,
    messageSuccess: `Rule was succesfully ${is_active ? 'disabled' : 'enabled'}`,
    messageError: 'An error occured while toggling rule',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Switch
        sx={{ mr: 1 }}
        size="small"
        checked={is_active}
        onChange={handleSwitchChange}
        disabled={updateResult.isLoading}
      />
      <span>{formattedValue === 'Calculator' ? 'Flows' : formattedValue}</span>
    </Box>
  );
};
