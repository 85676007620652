import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const useWindowSize = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const isDesktop = useMediaQuery(useTheme().breakpoints.up('md'));

  return {
    isMobile,
    isDesktop,
  };
};
