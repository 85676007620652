import { coreApi, rtkApiTags } from '@apiRtk/core';
import { usersApi } from '@apiRtk/users';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { startAppListening } from '@redux/middlewares/listenerMiddleware';
import { storage } from '@services/localStorage';
import { profileSwitcherActions } from '../profileSwitch';
import { setAccessTokenHeader, authBroadcastChannel, authBroadcastLogoutMsg } from './helpers';
import { initRefreshTokenInterceptor } from './interceptors';
import { authActions } from '.';

startAppListening({
  actionCreator: authActions.authorise,
  effect: async (action, listenerApi) => {
    try {
      listenerApi.dispatch(authActions.setIsAuthLoading(true));
      const { access_token, refresh_token, user_id } = action.payload;

      if (access_token && refresh_token && user_id) {
        setAccessTokenHeader(access_token);
        storage.set('refreshToken', refresh_token);
        storage.set('userId', user_id);

        initRefreshTokenInterceptor();

        const loggedUserResponse = await listenerApi.dispatch(
          usersApi.endpoints.getUser.initiate(user_id),
        );

        if ('data' in loggedUserResponse && loggedUserResponse.data) {
          listenerApi.dispatch(authActions.authSuccess(loggedUserResponse.data));

          authBroadcastChannel.onmessage = (msg) => {
            if (msg === authBroadcastLogoutMsg) {
              listenerApi.dispatch(authActions.logoutSuccess());
            }
          };
        } else {
          throw new Error();
        }
      }

      listenerApi.dispatch(authActions.setIsAuthLoading(false));
    } catch (error) {
      listenerApi.dispatch(authActions.logout());

      listenerApi.dispatch(
        createAlert({
          message:
            'It appears that your login session has expired. Please sign in again. Thank you.',
          variant: 'error',
        }),
      );

      listenerApi.dispatch(authActions.setIsAuthLoading(false));
    }
  },
});

startAppListening({
  actionCreator: authActions.logout,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(authActions.setIsAuthLoading(true));

    storage.remove('accessToken');
    storage.remove('refreshToken');
    storage.remove('userId');

    listenerApi.dispatch(profileSwitcherActions.setSessionActive(false));
    authBroadcastChannel.postMessage(authBroadcastLogoutMsg);
    listenerApi.dispatch(coreApi.util.invalidateTags(rtkApiTags));

    // TODO: send logout request to api
    listenerApi.dispatch(authActions.logoutSuccess());
    listenerApi.dispatch(authActions.setIsAuthLoading(false));
  },
});

startAppListening({
  actionCreator: authActions.reauthorise,
  effect: async (action, listenerApi) => {
    const userId = storage.get<string>('userId');
    const accessToken = storage.get<string>('accessToken');
    const refreshToken = storage.get<string>('refreshToken');

    if (!userId || !accessToken || !refreshToken) {
      listenerApi.dispatch(authActions.setIsAuthLoading(false));
      return;
    }

    listenerApi.dispatch(
      authActions.authorise({
        access_token: accessToken,
        refresh_token: refreshToken,
        user_id: parseInt(userId, 10),
      }),
    );
  },
});
