// TODO: Populate this dictionary with all commonly used error messages, titles, and other text strings across the application.
// This centralizes content management, making it easier to maintain consistency.
// Note: In the future, this dictionary can be replaced or extended with a more sophisticated translation solution.

export const dictionary = {
  errorCommon: 'Some unexpected error occured, please try again later.',
  errorNoData: 'No data available. Please try again later.',
  errorAddEcdToSite: 'Some error occured while adding ECD to site.',
};

export type DictionaryKeys = keyof typeof dictionary;
