import { useRedirect } from '@hooks';
import { RedirectBackRules } from '@hooks/useRedirectBack';
import { ArrowBack } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { QueryParams } from '@utils/routing/createQueryParamsStr';

type BackButtonProps = {
  path?: string;
  query?: QueryParams;
  onClick?: () => void;
} & ButtonProps &
  Pick<RedirectBackRules, 'forbiddenPaths' | 'noPreviousFallback'>;

export const BackButton = ({
  path,
  onClick,
  query,
  noPreviousFallback,
  forbiddenPaths,
  ...buttonProps
}: BackButtonProps) => {
  const redirect = useRedirect();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (path) {
      redirect(() => path, { query });
    }
  };

  return (
    <Button
      {...buttonProps}
      startIcon={<ArrowBack />}
      onClick={handleClick}
      variant="secondaryFade"
    />
  );
};
