import { ArrayElement } from '@appTypes/helpers/arrayElement';
import { OrganizationDto } from '@appTypes/models/organization.dto';
import { useRedirect } from '@hooks';
import { Box, Button } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import {
  OrganizationRelationsGrid,
  OrganizationRelationsGridProps,
} from './OrganizationRelationsGrid';

type SitesGridCellProps = GridCellParams<string, ArrayElement<OrganizationDto['sites']>>;

const CellWithRedirectButton = ({ row: { name, id } }: SitesGridCellProps) => {
  const redirect = useRedirect();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 120px',
      }}
    >
      <span>{`${name}`}</span>
      <Button
        variant="outlined"
        onClick={() => {
          redirect((paths) => paths.configurationSite(id));
        }}
      >
        Site detail
      </Button>
    </Box>
  );
};

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: false,
    flex: 1,
    renderCell: CellWithRedirectButton,
  },
];

export const SitesRelations = (
  props: Pick<OrganizationRelationsGridProps, 'searchTxt' | 'setSearchTxt'>,
) => (
  <OrganizationRelationsGrid<ArrayElement<OrganizationDto['sites']>>
    itemsField="sites"
    columns={columns}
    searchFields={['name']}
    {...props}
  />
);
