import { PropsWithChildren, useState } from 'react';
import { useGetPerformanceOverTimeQuery } from '@apiRtk/performanceOverTime';
import { FEATURE_FLAGS } from '@appTypes/common';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { Flex } from '@components/LayoutUtils';
import { getPerformanceOverTimeStaticMock } from '@mocks/performanceOverTime';
import { Grid, Typography } from '@mui/material';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import CONFIG from '@settings/config';
import { differenceInDays } from 'date-fns';
import CardBattery from './CardBattery';
import CardConsumption from './CardConsumption';
import CardRenewables from './CardRenewables';
import CardReturnOnInvestment from './CardReturnOnInvestment';
import { DateRangeDisplay } from './DateRangeDisplay';
import PeriodPicker, { periods } from './PeriodPicker';

const PerformanceOverTime = ({ siteId }: { siteId: number }) => {
  const [datetimeRange, setDatetimeRange] = useState({
    start: periods[0].start,
    end: periods[0].end,
  });

  const { error, isLoading, isFetching, data, refetch } = useGetPerformanceOverTimeQuery(
    {
      siteId,
      startDate: datetimeRange.start,
      endDate: datetimeRange.end,
      count: CONFIG.CARDS.GRAPHS_DATAPOINTS_COUNT,
    },
    {
      skip: !siteId,
    },
  );

  const loading = isLoading || isFetching || !data;

  const staticMock = getPerformanceOverTimeStaticMock(
    differenceInDays(datetimeRange.end, datetimeRange.start) + 1,
  );

  const handleChange = (start: Date, end: Date) => {
    setDatetimeRange({ start, end });
  };

  const heading = (
    <>
      <Typography variant="h5" pt={4} pb={2}>
        Performance over time
      </Typography>
      <Flex cX justifyContent="space-between" flexWrap="wrap" gap={1} pb={2}>
        <PeriodPicker onChange={handleChange} />
        <DateRangeDisplay start={datetimeRange.start} end={datetimeRange.end} />
      </Flex>
    </>
  );

  if (error) {
    return (
      <>
        {heading}
        <AlertWithRefetch severity="error" onRetryClick={refetch}>
          Performance over time data is not available for now, try again later.
        </AlertWithRefetch>
      </>
    );
  }

  return (
    <>
      {heading}
      <Grid container spacing={2}>
        <GridItem>
          <CardReturnOnInvestment
            isFeatureEnabled={isFeatureFlagEnabled(FEATURE_FLAGS.DISABLE_COMING_SOON_OVERLAYS)}
            loading={loading}
            data={staticMock.returnOnInvestment}
          />
        </GridItem>
        <GridItem>
          <CardRenewables data={data?.renewables} loading={loading} />
        </GridItem>
        <GridItem>
          <CardBattery data={data?.battery} loading={loading} />
        </GridItem>
        <GridItem>
          <CardConsumption data={data?.consumption} loading={loading} />
        </GridItem>
      </Grid>
    </>
  );
};

function GridItem({ children }: PropsWithChildren) {
  return (
    <Grid item xs={12} sm={6} md={6} sx={{ minWidth: '356px' }}>
      {children}
    </Grid>
  );
}

export default PerformanceOverTime;
