import { useEffect, useMemo, useState } from 'react';
import { ActionCreator } from '@appTypes/helpers/redux';
import { UserRole } from '@appTypes/models/user.dto';
import { AddButton } from '@components/Buttons/AddButton';
import { Flex } from '@components/LayoutUtils';
import { Tabs } from '@components/Tabs/Tabs';
import { useRedirect, useCurrentRole } from '@hooks';
import { Box, Grid } from '@mui/material';
import { fetchedEcdsCount, updateEcdsFilter } from '@redux/ducks/ecds/actionCreators';
import { getEcdsSelector } from '@redux/ducks/ecds/selectors';
import {
  fetchedOrganizationsCount,
  updateOrganizationsFilter,
} from '@redux/ducks/organization/actionCreators';
import { getOrganizationsSelector } from '@redux/ducks/organization/selectors';
import { fetchedSitesCount, updateSitesFilter } from '@redux/ducks/sites/actionCreators';
import { getSitesSelector } from '@redux/ducks/sites/selectors';
import { fetchedUsersCount, updateUsersFilter } from '@redux/ducks/users/actionCreators';
import { getUsersSelector } from '@redux/ducks/users/selectors';
import { paths } from 'paths';
import { useDispatch } from 'react-redux';
import {
  ConfigurationSearch,
  CurrentFilterAction,
} from './ConfigurationSearch/ConfigurationSearch';
import { ConfigurationTab } from './ConfigurationTab/ConfigurationTab';
import { EcdsList } from './Ecds/EcdsList';
import { OrganizationsList } from './Organizations/OrganizationsList';
import { SitesList, SitesListProps } from './Sites/SitesList';
import { ConfiguredItem, CurrentListSelector } from './types';
import { UsersList } from './Users/UsersList';

const itemTypes: {
  [key in ConfiguredItem]: {
    GridComponent: (props: object) => JSX.Element;
    label: string;
    selector?: CurrentListSelector;
    filterAction?: CurrentFilterAction;
    countAction?: ActionCreator<{ type: string }>;
    addNewPath?: string;
    addNewText?: string;
  };
} = {
  [ConfiguredItem.SITES]: {
    GridComponent: SitesList,
    label: 'Sites',
    addNewPath: paths.configurationSiteNew,
    addNewText: 'Create site',
    selector: getSitesSelector,
    filterAction: updateSitesFilter,
    countAction: fetchedSitesCount,
  },
  [ConfiguredItem.ECDS]: {
    GridComponent: EcdsList,
    label: 'ECDs',
    selector: getEcdsSelector,
    filterAction: updateEcdsFilter,
    countAction: fetchedEcdsCount,
  },
  [ConfiguredItem.ORGANIZATIONS]: {
    GridComponent: OrganizationsList,
    label: 'Organizations',
    addNewPath: paths.configurationOrganizationNew,
    addNewText: 'Create organization',
    selector: getOrganizationsSelector,
    filterAction: updateOrganizationsFilter,
    countAction: fetchedOrganizationsCount,
  },
  [ConfiguredItem.USERS]: {
    GridComponent: UsersList,
    label: 'Users',
    addNewPath: paths.configurationUserNew,
    addNewText: 'Create user',
    selector: getUsersSelector,
    filterAction: updateUsersFilter,
    countAction: fetchedUsersCount,
  },
};

const itemTypesEntries = Object.entries(itemTypes);

export type ConfigurationListProps = {
  initSelectedItem?: ConfiguredItem;
  onTabChange?: (value: ConfiguredItem) => void;
  visibleTabs?: ConfiguredItem[];
  showAddButtons?: boolean;
  customListsProps?: Partial<
    { [key in ConfiguredItem]: unknown } & {
      [ConfiguredItem.SITES]: SitesListProps;
    }
  >;
};

export const ConfigurationList = ({
  initSelectedItem = ConfiguredItem.SITES,
  onTabChange,
  visibleTabs,
  showAddButtons = true,
  customListsProps = {},
}: ConfigurationListProps) => {
  const [selectedItem, setSelectedItem] = useState(() => {
    if (visibleTabs && !visibleTabs.includes(initSelectedItem)) {
      return visibleTabs[0];
    }

    return initSelectedItem;
  });

  const dispatch = useDispatch();

  const { hasRole } = useCurrentRole();

  useEffect(() => {
    itemTypesEntries.forEach(([key, { countAction }]) => {
      if (key === selectedItem || !countAction) {
        return;
      }

      dispatch(countAction());
    });
  }, [dispatch, selectedItem]);

  const { tabs, tabsCount } = useMemo(() => {
    const itemTypesFilteredEls = visibleTabs
      ? itemTypesEntries.filter(([itemTypeName]) =>
          visibleTabs.includes(itemTypeName as ConfiguredItem),
        )
      : itemTypesEntries;

    return {
      tabs: itemTypesFilteredEls.map(([key, { label, selector }]) => (
        <ConfigurationTab
          key={key}
          selector={selector}
          label={label}
          value={key}
          style={key === ConfiguredItem.ORGANIZATIONS ? { width: 155 } : undefined}
        />
      )),
      tabsCount: itemTypesFilteredEls.length,
    };
  }, [visibleTabs]);

  const redirect = useRedirect();

  const { GridComponent, addNewPath, addNewText, selector, filterAction } = itemTypes[selectedItem];

  return (
    <Grid>
      <Grid container alignItems="center" rowSpacing={2} mb={2} mx={2}>
        <Grid item>
          {tabsCount > 1 && (
            <Box mr={2}>
              <Tabs<ConfiguredItem>
                value={selectedItem}
                onChange={(_, value) => {
                  setSelectedItem(value);

                  if (onTabChange) {
                    onTabChange(value);
                  }
                }}
              >
                {tabs}
              </Tabs>
            </Box>
          )}
        </Grid>
        <Flex item>
          <ConfigurationSearch currentListSelector={selector} currentFilterAction={filterAction} />
          {showAddButtons && addNewPath && hasRole(UserRole.ADMIN) && (
            <AddButton sx={{ ml: 2 }} onClick={() => redirect(() => addNewPath)}>
              {addNewText || 'Add'}
            </AddButton>
          )}
        </Flex>
      </Grid>
      <Grid>
        <GridComponent {...((customListsProps[selectedItem] as object) || {})} />
      </Grid>
    </Grid>
  );
};
