import { useState } from 'react';
import { DASHBOARD_TYPES } from '@appTypes/enums';
import { SiteIdName } from '@appTypes/models/site.dto';
import { Flex } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { MultilineChart, Widgets } from '@mui/icons-material';
import LocationOn from '@mui/icons-material/LocationOn';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import { routerHistory } from '@redux/ducks/routerHistory/reducer';
import { cssImp } from '@utils/utils';
import { paths } from 'paths';
import { NavLink, useParams } from 'react-router-dom';
import { palette } from 'styles/palette';

type SitesNavListProps = {
  sites: SiteIdName[];
  maxItemsVisible?: number;
  showSearchBox?: boolean;
  refetchLastSites?: boolean;
  setAllSitesModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

const dashboardConfigs = [
  {
    enabled: true,
    type: DASHBOARD_TYPES.OVERVIEW,
    caption: 'Site Overview',
    icon: <Widgets color="secondary" />,
    getPath: (siteId: string) => paths.siteOverview(siteId),
  },
  {
    enabled: true,
    type: DASHBOARD_TYPES.SUMMARY,
    caption: 'Data Analysis',
    icon: <MultilineChart color="secondary" />,
    getPath: (siteId: string) => paths.siteDataAnalysis(siteId),
  },
];

export const SitesNavList = ({
  maxItemsVisible,
  sites,
  showSearchBox = true,
  refetchLastSites = false,
  setAllSitesModalVisible,
}: SitesNavListProps) => {
  const [searchedSite, setSearchedSite] = useState('');
  const [expandedSiteId, setExpandedSiteId] = useState<Nullable<SiteIdName['id']>>(null);

  const params = useParams();
  const currentSiteId = parseInt(params.id as string, 10);

  if (sites.length === 0) {
    return (
      <Box p={2}>
        <Alert variant="filled" severity="info">
          Sorry, you don&apos;t have any sites linked with your account.
        </Alert>
      </Box>
    );
  }

  const sitesToRender = maxItemsVisible === undefined ? sites : sites.slice(0, maxItemsVisible);

  return (
    <>
      {showSearchBox && (
        <Box p={2}>
          <SearchInput value={searchedSite} onValueChange={(v) => setSearchedSite(v)} />
        </Box>
      )}
      {sitesToRender.map(({ id: siteId, name }) => {
        if (searchedSite && !name.toLowerCase().includes(searchedSite.toLowerCase())) {
          return null;
        }

        const isCurrentSite = siteId === currentSiteId;

        return (
          <Accordion
            key={siteId}
            expanded={siteId === expandedSiteId || sites.length === 1 || isCurrentSite}
            onChange={() => setExpandedSiteId(siteId === expandedSiteId ? null : siteId)}
            sx={{ boxShadow: 'none' }}
          >
            <AccordionSummary
              sx={{
                cursor: cssImp('pointer'),
              }}
            >
              <Box
                display="grid"
                gridTemplateColumns="auto 1fr"
                gap={0.5}
                data-cy={`allsites-modal-item-${name}`}
              >
                <LocationOn color="secondary" />
                <Typography
                  sx={{
                    fontWeight: isCurrentSite ? 'bold' : 'normal',
                  }}
                >
                  {name}
                </Typography>
              </Box>
            </AccordionSummary>
            {dashboardConfigs
              .filter((cfg) => cfg.enabled)
              .map(({ icon, caption, getPath, type }) => {
                const dashboardId = `${siteId}.${type}`;
                const paramId = [DASHBOARD_TYPES.OVERVIEW, DASHBOARD_TYPES.SUMMARY].includes(type)
                  ? siteId.toString()
                  : dashboardId;

                const onItemClick = () => {
                  routerHistory.push(getPath(paramId));
                  setAllSitesModalVisible?.(false);
                };

                return (
                  <AccordionDetails key={dashboardId}>
                    <Flex ml={2} gap={0.5} alignItems="center">
                      {icon}
                      <Typography>
                        <NavLink
                          data-cy={`allsites-modal-subitem-${caption}`}
                          onClick={refetchLastSites ? onItemClick : undefined}
                          to={getPath(paramId)}
                          style={({ isActive }) => ({
                            fontWeight: isActive ? 'bold' : '',
                            color: palette.primary.main,
                            textDecoration: 'none',
                          })}
                        >
                          {caption}
                        </NavLink>
                      </Typography>
                    </Flex>
                  </AccordionDetails>
                );
              })}
          </Accordion>
        );
      })}
    </>
  );
};
