import { Button, Grid, GridProps } from '@mui/material';
import { FieldValues, UseFormRegister, UseFormReturn, UseFormWatch } from 'react-hook-form';
import { FormInput, FormInputProps } from './FormInput';

export type BasicFormField = Omit<
  FormInputProps,
  'fullWidth' | 'initValue' | 'value' | 'autoComplete' | 'error'
>;

export type BasicFormProps = {
  fields: BasicFormField[];
  readOnly?: boolean;
  submitBtnLabel?: string;
  watch: UseFormWatch<any>;
  register: UseFormRegister<any>; // TODO: try to infer types from TFieldValues
} & Pick<UseFormReturn<FieldValues>, 'formState'> &
  Pick<GridProps, 'columns'>;

export const BasicForm = ({
  fields,
  watch,
  register,
  columns = 2,
  readOnly: formReadOnly,
  submitBtnLabel = 'Save',
  formState: { errors },
}: BasicFormProps) => (
  <Grid container columns={columns} spacing={2}>
    {fields.map(({ name, readOnly, CustomComponent, required = true, ...formInpProps }) => (
      <Grid item key={name} xs={2} lg={1}>
        <FormInput
          CustomComponent={CustomComponent}
          required={required}
          fullWidth
          initValue={formReadOnly || readOnly ? watch(name) : undefined}
          value={CustomComponent ? watch(name) : undefined}
          readOnly={formReadOnly || readOnly}
          autoComplete={name}
          {...register(name)}
          error={errors[name]}
          {...formInpProps}
        />
      </Grid>
    ))}
    {!formReadOnly && (
      <Grid item xs={2} display="grid" justifyItems="center" py={2}>
        <Button type="submit">{submitBtnLabel}</Button>
      </Grid>
    )}
  </Grid>
);
