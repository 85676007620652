import { useState } from 'react';
import { useGetSiteQuery } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api/error';
import { BackButton } from '@components/Buttons/BackButton';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { RelationsTabs } from '@components/RelationsForm/RelationsTabs';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { Alert, CircularProgress, Grid } from '@mui/material';
import { paths } from 'paths';
import { useParams } from 'react-router-dom';
import EcdList from './UpdateSite/Relations/EcdList';
import { EcdRelationModal } from './UpdateSite/Relations/EcdRelationModal';
import UserList from './UpdateSite/Relations/UserList';
import { UserRelationModal } from './UpdateSite/Relations/UserRelationModal';
import UpdateSiteForm from './UpdateSiteForm';

const UpdateSite = () => {
  const params = useParams();
  const id = params.id as string;
  const siteId = parseInt(id, 10);

  const tabs: ConfiguredItem[] = [ConfiguredItem.ECDS, ConfiguredItem.USERS];

  const [userRelationModalOpen, setUserRelationModalOpen] = useState(false);
  const [ecdRelationModalOpen, setEcdRelationModalOpen] = useState(false);
  const [relationsTabVal, setRelationsTabVal] = useState(ConfiguredItem.ECDS);

  const {
    data: siteData,
    isLoading: siteLoading,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const typedSiteError = siteError as ApiErrorPayload | undefined;

  if (Number.isNaN(siteId)) return null;

  if (siteLoading) {
    return <CircularProgress />;
  }

  if (typedSiteError && typeof typedSiteError.data.detail === 'string') {
    return (
      <Alert severity="error">
        {typedSiteError && typeof typedSiteError.data.detail === 'string'
          ? typedSiteError.data.detail
          : 'We encountered an error while processing your request. Please try again later.'}
      </Alert>
    );
  }

  return (
    <>
      <BackButton path={paths.configuration}>Back to configuration list</BackButton>
      <TitleBar my={4} title={`Update site "${siteData?.name}"`} titleVariant="h5" />

      <Grid container spacing={{ xs: 4, lg: 10 }} width="100%" px={2} pt={0}>
        <Grid item xs={12} md={12} lg={4} pt={0}>
          <UpdateSiteForm siteId={siteId} siteData={siteData} />
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <RelationsTabs value={relationsTabVal} tabs={tabs} onValueChange={setRelationsTabVal} />
          {relationsTabVal === ConfiguredItem.ECDS && (
            <EcdList siteData={siteData} onAddRelatedECDs={() => setEcdRelationModalOpen(true)} />
          )}
          {relationsTabVal === ConfiguredItem.USERS && (
            <UserList siteData={siteData} onAddRelatedUser={() => setUserRelationModalOpen(true)} />
          )}
        </Grid>
      </Grid>

      <EcdRelationModal open={ecdRelationModalOpen} setOpen={setEcdRelationModalOpen} />
      <UserRelationModal open={userRelationModalOpen} setOpen={setUserRelationModalOpen} />
    </>
  );
};

export default UpdateSite;
